import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import ImageTest from "../../../../Components/SwiperHome/imageTest";

function PcCatalogElement({data, text, category, index, setSkeletonState, skeletonState, categoryID}) {
    function isAllC() {
        return data.AllConfigurator === true
    }

    useEffect(() => {
        setTimeout(() => {
            setSkeletonState(false)

        }, 5000)
    }, []);
    if (isAllC()) {
        return ''
    }
    if (!category.attributes)
        return ""

    function addCategoryParam() {
        if (category?.attributes?.url.indexOf('productsCatalog') === -1 &&
            category?.attributes?.url.indexOf('collections') === -1) {
            return `?category=${categoryID}`
        }
        return ''

    }

    return (<div key={category.id} className={[
        category.configurator !== true ? "sofas__net-item" : 'bedroom__net-item',
        index === 0 ? 'i2' : '',
        category.configurator === true ? 'i3' : ''

    ].join(' ')}>
        <div
            className={
                skeletonState
                    ? "skeleton-load-img active"
                    : "skeleton-load-img"
            }
        >
            <img src={"/img/header__logo-short.png"}/>
        </div>
        {category.configurator !== true && <Link
            to={"/" + category?.attributes?.url + addCategoryParam()}
            className="sofas__item-link"
        >
            {category.attributes.title}
        </Link>
        }
        {category.configurator === true &&
            <h6>{category.attributes.title}</h6>
        }
        {category.attributes.img.data && <ImageTest
            className="main"
            removeWatermark={true}
            src={

                category?.attributes?.img?.data?.attributes?.url ? category.attributes.img.data.attributes.url : 'null'
            }
            alt=""
            onLoad={() => {
                data.length - 1 === index && setSkeletonState(false);
            }}
        />}

        {category.configurator === true && <div className="bedroom__button-wr">
            <Link
                to={"/" + category.attributes.url}
                className="bedroom__configurator-btn"
            >
                {text[0]?.attributes?.categoriesConfigureNow}

            </Link>
            <img
                className="bedroom__configurator-arrow"
                src={process.env.PUBLIC_URL + "/img/bedroom__arrow.svg"}
                alt="arrow"
            />
        </div>
        }
    </div>)
}

export default PcCatalogElement;
