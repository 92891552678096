import {createSlice} from '@reduxjs/toolkit'

export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        products: [],
        open: false,
        shipCost: 0,
        shipType: 'self'

    },

    reducers: {

        setShipCost(state, action) {
            state.shipCost = action.payload;
        },
        setShipType(state, action) {
            state.shipType = action.payload;
        },
        setOpenCart(state, action) {
            state.open = action.payload;
        },
        addToCart: (state, action) => {
            const itemIndex = state.products.findIndex((item) => item.id === action.payload.id);
            const random = new Date().getTime().toString() + Math.round(Math.random() * 9999999999);
            /*  if (itemIndex !== -1) {
                  // Якщо item існує, оновлюємо quantity та інші поля з action.payload
                  state.products[itemIndex] = {
                      ...state.products[itemIndex],
                      ...action.payload,
                      quantity: (+state.products[itemIndex].quantity) + (+action.payload.quantity),
                  };
              } else {
                  // Якщо item не існує, просто додаємо action.payload до масиву*/
            state.products.push({...action.payload, cartProductId: random});
            /*   }*/
        },


        removeItem: (state, action) => {
            state.products = state.products.filter(item => item.cartProductId !== action.payload)
        },


        replaceItem: (state, action) => {
            const {id, quantity} = action.payload;

            const item = state.products.find((item) => item.cartProductId == id);

            if (item) {
                item.quantity = quantity;
            }
        },

        resetCart: (state) => {
            state.products = [];
        }

    }
});


// Action creators are generated for each case reducer function
export const {addToCart, setOpenCart, removeItem, setShipCost, setShipType, replaceItem, resetCart} = cartSlice.actions

export default cartSlice.reducer
