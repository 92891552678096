import React from 'react';
import {useParams} from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useSelector,useDispatch } from 'react-redux'
import { clearUser } from '../../Redux/adminUserReducer'
import useFetch from '../../Hooks/useFetch'
import AdminEditStatusCostDelivery from '../../Components/AdminEditStatusCostDelivery';

function AdminCostDeliverySingle() {
 
    const [openEditStatus,setOpenEditStatus] = React.useState(false)

    const { id } = useParams()
    const navigate = useNavigate()
    const userAuthorization = useSelector(state => state.adminUser.user);
    const dispatch = useDispatch()

    const checkAuthorization = () => {
        userAuthorization.length === 0  && navigate('/admin-panel')
    }

    const logOut = () =>{
        dispatch(clearUser())
        navigate('/admin-panel')
    }

    React.useEffect(()=>{
        checkAuthorization();
    },[]);


    const { data, loading, error} =
    useFetch(`${process.env.REACT_APP_API_URL}/calculate-shippings/${id}?populate=*`);


  

    return (

        (!loading &&  data.id) && <div className="cost-delivery-single-information">


            <section>
                <p>ID:</p>
                <span>{data?.id}</span>
            </section>

            <section>
                <p>Ім'я:</p>
                <span>{data?.attributes?.name}</span>
            </section>

            <section>
                <p>Номер телефону:</p>
                <span>{data?.attributes?.phone}</span>
            </section>

            <section>
                <p>Країна:</p>
                <span>{data?.attributes?.country}</span>
            </section>

            <section>
                <p>Місто:</p>
                <span>{data?.attributes?.city}</span>
            </section>

            <section>
                <p>Поштовий індекс:</p>
                <span>{data?.attributes?.zipCode}</span>
            </section>

            <section>
                <p>Адрес:</p>
                <span>{data?.attributes?.adress}</span>
            </section>

            <section>
                <p>Статус заявки:</p>

            <div className="d-flex a-i-center gap8">

                   <span>{data?.attributes?.status === 'Added' ? 'Додана' : 'Переглянута'}</span>
                  <img onClick={()=>setOpenEditStatus(!openEditStatus)} alt="button edit" src={process.env.PUBLIC_URL + '/img/edit-for-admin-panel.svg'} className="edit-icon" />
             
                </div>
              

            </section>

           
        <Link to='/admin-panel-calculate-cost-delivery'>
        <img className='catalog-orders-button' src={process.env.PUBLIC_URL + '/img/list-order-admin.svg'} alt="catalog orders" />
</Link>

   
<img onClick={() => logOut()}  className='admin-exit' src={process.env.PUBLIC_URL + '/img/exit-admin.svg'} alt="exit" />

        <AdminEditStatusCostDelivery
        openEditStatus={openEditStatus}
        setOpenEditStatus={setOpenEditStatus}
        id={data?.id}
        status={data?.attributes?.status}
        />

</div>
    );
}

export default AdminCostDeliverySingle;