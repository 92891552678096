import React, {useRef} from 'react';
import './adminPanelBody.scss';
import useFetch from '../../Hooks/useFetch';
import {Link} from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import { useSelector,useDispatch } from 'react-redux'
import { clearUser } from '../../Redux/adminUserReducer';

function AdminPanelBody() {


    const navigate = useNavigate()


    const userAuthorization = useSelector(state => state.adminUser.user);
    const dispatch = useDispatch()

    const checkAuthorization = () => {
        userAuthorization.length === 0  && navigate('/admin-panel')
    }

    const logOut = () =>{
        dispatch(clearUser())
        navigate('/admin-panel')
    }

    React.useEffect(()=>{
        checkAuthorization();
    },[]);


    const componentRef = useRef(null);

    React.useEffect(() => {
        if (componentRef.current) {
          componentRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, []);

    const [currentPage,setCurrentPage] = React.useState(1);
    const [orders, setOrders] = React.useState([]);

    const { data, loading, error , pagination } = 

    useFetch(`${process.env.REACT_APP_API_URL}/unpaid-orders?pagination[page]=${currentPage}&pagination[pageSize]=9&filters[type][$ne]=Pending&populate=*`);

    const { data: text, loading: loadingText, error: errorText } = useFetch(`${process.env.REACT_APP_API_URL}/Text-for-pdfs?locale=uk-UA&populate=*`);


    React.useEffect(() => {
        if (!loading && !error && data) {
            setOrders((prevOrders) => [...prevOrders, ...data]);
            
            if(componentRef.current && !loading && currentPage !== 1){
            setTimeout(()=>{

               (componentRef && componentRef.current) && componentRef.current.scrollIntoView({ behavior: 'smooth' });
            },0)
        }

        }
      }, [data, loading, error]);

   
   
    return (
        !loading && <div  className="admin-panel__body ">

                    <div className="admin-main-navigation">
                        
                        <div>
                              <Link to="/admin-panel-calculate-cost-delivery">Заявки на розрахунок вартості >>></Link>
                        </div>
                        
                        <div>
                              <Link to="/admin-panel-consultation">Заявки на консультацію >>></Link>
                        </div>

                        <div className="admin-title">Інформація про ваші замовлення:</div>
                        
                    </div>

                    <div  className="admin-panel__order hover-none">
                            <div className='timestamp fw500'>  
                                 Трек-номер:
                            </div>
                            <div className="payment-status cen fw500">Статус оплати:</div>
                            <div className="payment-status  cen fw500">Статус замовлення:</div>
                            <div className="payment-status  cen fw500">Загальна сума замовлення:</div>
                            <div className="payment-status  cen fw500">Активований промокод:</div>
                            <div className="payment-status  cen fw500 br-none">Кількість товарів:</div>
                    </div>

          

{
            orders?.map((order,index)=>(


                    <Link to={"https://shadeko.eu/admin-panel-orders/"+order?.attributes?.timeStamp} key={index+'orderAdminPanel'+order.id} className="admin-panel__order">
                            <div className='timestamp'>{order?.attributes?.timeStamp}</div>

                            <div className="payment-status cen">
                                
                                {
  order?.attributes?.type === 'Not paid' ? (
    'Не оплачено'
  ) : order?.attributes?.type === 'Paid' ? (
    'Оплачено'
  ) : order?.attributes?.type === 'Part paid' ? (
      'Оплачено частину'
  ) : null
}
                                </div>

                            <div className="payment-status order-status cen">

{(() => {
                            let statusObj;

                            if(order?.attributes?.status === 'Pending maneger review'){
                        
                                statusObj = {
                                    text: text[0]?.attributes?.statusOrderOption1,
                                    color: 'redC',
                                }
                        
                            }else if(order?.attributes?.status === 'Processing'){
                        
                                statusObj = {
                                    text: text[0]?.attributes?.statusOrderOption2,
                                    color: 'yellowC',
                                }
                        
                        
                            }else if(order?.attributes?.status === 'Shipped'){
                        
                                statusObj = {
                                    text: text[0]?.attributes?.statusOrderOption3,
                                    color: 'yellowC',
                                }
                            
                            }else if(order?.attributes?.status === 'Delivered'){
                        
                                statusObj = {
                                    text: text[0]?.attributes?.statusOrderOption4,
                                    color: 'greenC',
                                }
                             
                            }else if(order?.attributes?.status === 'Canceled'){
                        
                                statusObj = {
                                    text: text[0]?.attributes?.statusOrderOption5,
                                    color: 'redC',
                                } 
                        
                            }else if(order?.attributes?.status === 'Return requested'){
                        
                                statusObj = {
                                    text: text[0]?.attributes?.statusOrderOption6,
                                    color: 'yellowC',
                                } 
                        
                            }else if(order?.attributes?.status === 'Return complited'){
                        
                                statusObj = {
                                    text: text[0]?.attributes?.statusOrderOption7,
                                    color: 'greenC',
                                }
                        
                        
                            }else{
                                statusObj = {
                                    text: 'Undefined',
                                    color: 'redC',
                                }
                            }
                            return <span>{statusObj?.text}</span>;
                        })()}

                  
                            </div>

                            <div className="admin-order-totalPrice cen mx">
                                {
                                    order?.attributes?.totalPrice + '$'
                                }
                            </div>

                            <div className="admin-order-totalPrice cen ">
                                {
                                    order?.attributes?.promocodePercent + '%'
                                }
                            </div>

                            <div className="admin-order-totalPrice cen br-none">
                                {
                                  

                                    (()=> {

                                        let count = 0;

                                        order.attributes.productsDetails.map((el)=>(
                                            count += el.quantity
                                        ))

                                             return <span>{count}</span>;
                                    })()
                                }
                    
                            </div>

                            
                    </Link>
            ))
}


					<img onClick={() => logOut()} className='admin-exit' src={process.env.PUBLIC_URL + '/img/exit-admin.svg'} alt="exit" />
		


        <div ref={componentRef} className="w100 d-flex j-c-center">
                  <button onClick={()=>{setCurrentPage(currentPage + 1)}} className={pagination?.pageCount === currentPage ? 'product__more-button none' : 'product__more-button' }>Показати ще</button>
        </div>
        

        </div>
    );
}

export default AdminPanelBody;
