import React from "react";
import CatalogFilterElement from "../CatalogFilter/catalogFilterElement";

import Scrollbars from 'react-scrollbars-custom';

function SelectRows({text, isActive, toggleActiveIndex}) {

    function openPanel() {
        toggleActiveIndex()
    }

    function saveRowsInLocalStorage(rows) {
        localStorage.setItem("rowsInCatalog", rows)
        window.dispatchEvent(new Event('storage'))
    }

    function readRowsFromLocalStorage() {
        if (localStorage.getItem('rowsInCatalog') != null) {
            return localStorage.getItem('rowsInCatalog')
        }
        return 3
    }

    const options = [
        {
            title: '2 ' + text[0]?.attributes?.catalog_views_column,
            rows: 2
        },
        {
            title: '3 ' + text[0]?.attributes?.catalog_views_column,
            rows: 3
        },
        {
            title: '4 ' + text[0]?.attributes?.catalog_views_column,
            rows: 4
        },
        {
            title: '5 ' + text[0]?.attributes?.catalog_views_column,
            rows: 5
        }
    ]

    return (


        <div
            className={['catalog__filter-item'].join(' ')}>
            <div onClick={openPanel} className="cu-po catalog__filter-title d-flex a-i-center ">
                <span>{text[0]?.attributes?.catalog_views}</span>
                <img className={isActive ? 'rotate' : ''} src={process.env.PUBLIC_URL + '/img/header__list-arrow.svg'} alt="arrow"/>
            </div>
            <Scrollbars className={isActive ? "catalog__filter-body active" : "catalog__filter-body"}>

                <>

                    {options.map((element, index) => <CatalogFilterElement
                        key={index}
                        element={element}
                        text={text}
                        close={openPanel}
                        onChildItemClick={() => {
                            saveRowsInLocalStorage(element.rows)
                        }}
                        title={element.title}
                    />)}
                </>

            </Scrollbars>

        </div>

    );
}

export default SelectRows;
