import React, {useEffect, useState} from "react";
import "./ProductSingle.scss";
import {Link, useNavigate, useParams} from "react-router-dom";
import Card from "./../../Components/Card";
import useFetch from "../../Hooks/useFetch";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {addToCart} from "../../Redux/cartReducer";
import ConfiguratorElementColor from "../../Components/ConfiguratorElementColor";
import ConfiguratorElementLong from "../../Components/ConfiguratorElementLong";
import ConfiguratorElementShort from "../../Components/ConfiguratorElementShort";
import Galery from "../../Components/Galery";
import Skeleton from "react-loading-skeleton";
import SwiperCore, {Navigation, Swiper, Thumbs} from "swiper";
import SingleModule from "../../Components/SingleModules";
import {Helmet} from "react-helmet-async";
import {changeCurrency} from "../../functions/changeCurrency";
import {addToFavorite, removeFromFavorite, updateInFavorite} from "../../Redux/favoriteReducer";
import ScrollToTop from "../../Components/ScrollToTop";
import ImageTest from "../../Components/SwiperHome/imageTest";
import PrevNextProduct from "./prevNextProduct";


function ProductSingle({text}) {
    SwiperCore.use([Navigation, Thumbs]);
    const canonicalUrl = window.location.href;
    const languageInformation = useSelector((state) => state.language.language);
    const navigate = useNavigate()
    const isTouchDevice = "ontouchstart" in window || navigator.msMaxTouchPoints;


    const [skeletonQuantityState, setSkeletonQuantityState] =
        React.useState(true);
    const [skeletonPreviewSliderState, setSkeletonPreviewSliderState] =
        React.useState(true);
    const [skeletonMainSliderState, setSkeletonMainSliderState] =
        React.useState(true);
    const [quantity, setQuantity] = React.useState(1);
    const [currentColor, setCurrentColor] = React.useState(0);
    const [currentMaterial, setCurrentMaterial] = React.useState(0);
    const [dekorAdd, setDekorAdd] = React.useState(false);
    const [dekorAdd_fav, setDekorAdd_fav] = React.useState(false);
    const [colorsApi, setColorsApi] = React.useState([]);
    const [colorsLoading, setColorsLoading] = React.useState(false);
    const [materialsApi, setMaterialsApi] = React.useState([]);
    const [materialsLoading, setMaterialsLoading] = React.useState([]);
    const [similarApi, setSimilarApi] = React.useState([]);
    const [collectionApi, setCollectionApi] = React.useState([]);
    const [collectionId, setCollectionId] = React.useState([]);
    const [colorsIdArr, setColorsIdArr] = React.useState([]);
    const [materialsIdArr, setMaterialsIdArr] = React.useState([]);
    const [currentCategories, setCurrentCategories] = React.useState([]);
    const [materialPreviewIndex, setMaterialPreviewIndex] = React.useState(600);
    const [colorPreviewIndex, setColorPreviewIndex] = React.useState(600);
    const [materialArticle, setMaterialArticle] = React.useState("");
    const [colorArticle, setColorArticle] = React.useState("");
    const [materialObj, setMaterialObj] = React.useState({});
    const [colorObj, setColorObj] = React.useState({});
    const [updateWish, setUpdateWish] = useState(0)
    const [items, setItems] = React.useState([]); // Стан зі списком об'єктів { id, price }
    const dispatch = useDispatch();
    const [startIndex, setStartIndex] = useState(0)
    const {id, profile, wishId} = useParams();
    const {
        data: product,
        loading: loadingProduct,
        error: errorProduct,
    } = useFetch(`${process.env.REACT_APP_API_URL}/products?locale=${languageInformation.language}&filters[masterId][$eq]=${id}&populate=*`, {findOne: true});

    const wishArray = useSelector(state => state.favorite.favorite)
    useEffect(() => {

        if (!wishId) return


        const findedProduct = wishArray.find(element => {
            if (!element.uniqId) {
                console.log("No uniqId")
                return false;
            }
            return element.uniqId.toString() === wishId.toString()
        });
        const findedProductIndex = wishArray.findIndex(element => {
            if (!element.uniqId) {
                console.log("No uniqId")
                return false;
            }
            return element.uniqId.toString() === wishId.toString();
        })
        if (!findedProduct) {
            navigate('/');
            return
        }

        setMaterialArticle(findedProduct.materialArticle)
        setCurrentMaterial(findedProductIndex)
        /* changeCurrentSetMaterial(index);
         setMaterialArticle(e.target.dataset.article);
*/

        try {
            if (product.attributes) {
                let index = product.attributes.materials.data.findIndex(k => {
                    return k.attributes.name == findedProduct.material.name
                })
                if (index != -1) {
                    if (product?.attributes?.[`img_color_${index + 1}`].data == null) {
                        setCurrentSetPhoto(1)
                    } else {
                        setCurrentSetPhoto(index + 1)
                    }

                }


            }

            setMaterialObj({
                img: findedProduct.material.img,
                name: findedProduct.material.name,
            });


            setQuantity(findedProduct.quantity)
            const findColorIndex = colorsApi.findIndex(color => {
                return color.attributes.name == findedProduct.color.name
            })
            const findColor = colorsApi.find(color => {
                return color.attributes.name == findedProduct.color.name
            })

            if (findColor) {
                setColorArticle(findColor.attributes.article);
                changeCurrentSetColor(findColorIndex);
                setColorObj({
                    img: process.env.REACT_APP_IMG +
                        findColor?.attributes?.img?.data?.attributes?.url,
                    name: findColor.attributes.name,
                });
                setCurrentColor(findColorIndex)
                clickOnColor(findColorIndex);


            }
        } catch (error) {
            console.log(error)
        }

    }, [wishId, colorsApi, product]);

    const {
        data: modules,
        loading: loadingModules,
        error: errorModules,
    } = useFetch(
        `${process.env.REACT_APP_API_URL}/modules?filters[products][masterId]=${id}&populate=*`,
    );
    const [moduleLock, setModuleLock] = useState(true)
    useEffect(() => {
        if (modules.length > 0) {
            setTimeout(() => {
                setModuleLock(false)
            }, 10)
        }

    }, [modules]);


    const {
        data: seo,
        loading: seoLoading,
        error: seoError,
    } = useFetch(
        process.env.REACT_APP_API_URL +
        `/seos?locale=${languageInformation.language}&filters[route]=single&populate=*`,
    );

    const [currentSetPhoto, setCurrentSetPhoto] = React.useState(1);
    const [modulesDrawer, setModulesDrawer] = React.useState([]);

    let mainSwiperRef = React.useRef(null);
    const previewSwiperRef = React.useRef(null);

    useEffect(() => {
        if (!loadingProduct && product && text[0]) {

            previewSwiperRef.current && previewSwiperRef.current.destroy(false, false);
            previewSwiperRef.current = new Swiper(".swiper-miniature", {
                slidesPerView: 6,
                spaceBetween: 4,
                slideToClickedSlide: true,
                isEnd: false,
                observer: true
            });

            mainSwiperRef.current && mainSwiperRef.current.destroy(false, false);
            mainSwiperRef.current = new Swiper(".swiper-single", {
                navigation: {
                    nextEl: ".arrow__next",
                    prevEl: ".arrow__previous",
                },
                initialSlide: 0,
                // thumbs: {
                //     swiper: previewSwiperRef.current
                // },
                thumbs: {
                    swiper:
                        previewSwiperRef.current && !previewSwiperRef.current.destroyed
                            ? previewSwiperRef.current
                            : null,
                },
            });


            if (mainSwiperRef.current.wrapperEl) {
                mainSwiperRef.current.wrapperEl.style.transform =
                    "translate3d(0px, 0px, 0px)";
            }
        }
    }, [loadingProduct, product, currentSetPhoto, text]);

    const [availableSets, setAvailableSets] = React.useState([]);
    const currency = useSelector((state) => state.language.language);
    React.useEffect(() => {
        if (!product || product == undefined) {
            navigate('/')
            return
        }

        const sets = [
            product.attributes?.img_color_1.data,
            product.attributes?.img_color_2.data,
            product.attributes?.img_color_3.data,
            product.attributes?.img_color_4.data,
            product.attributes?.img_color_5.data,
            product.attributes?.img_color_6.data,
            product.attributes?.img_color_7.data,
            product.attributes?.img_color_8.data,
            product.attributes?.img_color_9.data,
            product.attributes?.img_color_10.data,
        ];

        if (product.attributes?.img_color_1.data) {
            sets.map((e, i) => {
                if (e !== null) {
                    setAvailableSets((prev) => [...prev, i + 1]);
                }
            });
        }
    }, [loadingProduct, product, id]);


    //change material set
    const changeCurrentSetMaterial = (index) => {
        availableSets.forEach((e) => {
            if (e === index + 1) {
                setCurrentSetPhoto(index + 1);
            }
        });

    };

    //change color set
    const changeCurrentSetColor = (index) => {
        availableSets.forEach((e) => {
            if (e === index + 1) {
                setCurrentSetPhoto(index + 1);
            }
        });

    };

    useEffect(() => {

        if (!wishId) {
            setCurrentColor(0);
            setCurrentSetPhoto(1);

        }
        setCurrentMaterial(0);
        setMaterialObj({});
        setColorObj({});
    }, [id]);

    const cartFavorite = useSelector((state) => state.favorite.favorite);
    const isInCart = wishId ? true : false// cartFavorite.some((item) => item.id === product.id);
    const [textFavorite, setTextFavorite] = React.useState(
        wishId && wishId.toString().length > 4
    );


    const [lightboxOpen, setLightboxOpen] = React.useState(false);
    const openLightBox = (id) => {
        setStartIndex(id)
        setLightboxOpen(true);
    }
    const closeLightbox = () => {

        setLightboxOpen(!lightboxOpen);

    };

    const [submodulesConfigurations, setSubmodulesConfigurations] =
        React.useState([]);


    const CollectionModuleConfig = (newConfigs) => {
        newConfigs.forEach((newData) => {
            if (newData.initial === true) {
                setSubmodulesConfigurations((prev) => [...prev, newData]);
            } else {
                submodulesConfigurations.forEach((old) => {
                    if (old.module === newData.module) {
                        if (old.submodule === newData.submodule) {
                            const clearArr = submodulesConfigurations.filter(
                                (el) => el.submodule !== newData.submodule,
                            );

                            newConfigs.forEach((el) => {
                                clearArr.push(el);
                            });

                            setSubmodulesConfigurations(clearArr);
                        }
                    }
                });
            }
        });
        //   console.log('updata');
        //  dispatch(updateInFavorite(createWishObj(wishId)))
    };

    const fetchData = async () => {
        try {
            setColorsLoading(false);
            const responses = await Promise.all(
                colorsIdArr.map((el) =>
                    axios.get(
                        `${process.env.REACT_APP_API_URL}/colors/${el}?populate=*`,
                        {
                            headers: {
                                Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
                            },
                        },
                    ),
                ),
            );

            const colorsData = responses.map((response) => response.data.data);
            setColorsApi(colorsData);
            setColorArticle(colorsData[0].attributes.article);

            setColorObj({
                img:
                    process.env.REACT_APP_IMG +
                    colorsData[0].attributes?.img?.data?.attributes?.url,
                name: colorsData[0].attributes.name,
            });


        } catch (err) {
            // Обробка помилки
        }
        setColorsLoading(true);
    };

    const materialsData = async () => {
        try {
            setMaterialsLoading(false);
            const responses = await Promise.all(
                materialsIdArr.map((el) =>
                    axios.get(
                        `${process.env.REACT_APP_API_URL}/materials/${el}?populate=*`,
                        {
                            headers: {
                                Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
                            },
                        },
                    ),
                ),
            );

            const materialsData = responses.map((response) => response.data.data);
            setMaterialsApi(materialsData);
            if (!wishId) {
                setMaterialArticle(materialsData[0].attributes.article);
            }

            setMaterialObj({
                img:
                    process.env.REACT_APP_IMG +
                    materialsData[0].attributes?.img?.data?.attributes?.url,
                name: materialsData[0].attributes.name,
            });
        } catch (err) {
            // Обробка помилки
        }
        setMaterialsLoading(true);
    };

    const simularData = async () => {
        try {
            const responses = await axios.get(
                `${process.env.REACT_APP_API_URL}/products?populate=*&[filters][subcategories][id]=${currentCategories[5][0].id}&[filters][id][$ne]=${currentCategories[3]}&pagination[page]=1&pagination[pageSize]=10`,
                {
                    headers: {
                        Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
                    },
                },
            );

            const simularData = responses.data.data;

            setSimilarApi(simularData);
        } catch (err) {
            // Обробка помилки
        }
    };

    const collectionData = async () => {
        //   console.log(`col:${collectionId}`);

        if (collectionId && collectionId.length > 0) {
            try {
                const responses = await axios.get(
                    `${process.env.REACT_APP_API_URL}/products?filters[collection][url][$containsi]=${collectionId}&pagination[page]=1&pagination[pageSize]=10&populate=*`,
                    {
                        headers: {
                            Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
                        },
                    },
                );

                const collectionData = responses.data.data;

                setCollectionApi(collectionData);
            } catch (err) {
                console.log(err);
            }
        } else {
            setCollectionApi([]);
        }
    };

    const [modulesState, setModules] = React.useState(modules);

    const handleModuleChange = (moduleId, changedPrice, newQuantity, person) => {

        setModules((prevModules) => {

            const updatedModules = prevModules.map((module) => {
                if (module.id === moduleId) {
                    return {
                        ...module,
                        price: changedPrice,
                        attributes: {
                            ...module.attributes,
                            initialAmount: newQuantity,
                        },
                    };
                }
                return module;
            });

            const newTotalPrice = calculateTotalPrice(updatedModules);

            setTotalPrice(newTotalPrice);

            return updatedModules;
        });

        if (
            person) {
            setTimeout(() => {

                setUpdateWish(updateWish + 1)
            }, 10)

        }

    };
    useEffect(() => {

        if (updateWish == 0) return
        //   console.log(updateWish, createWishObj());
        //  return //! !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        dispatch(updateInFavorite(createWishObj(wishId)))
    }, [updateWish]);

    const calculateTotalPrice = (updatedModules) => {
        return updatedModules.reduce(
            (total, module) =>
                total + module.attributes.price * module.attributes.initialAmount,
            0,
        );
    };

    const [totalPrice, setTotalPrice] = React.useState(
        calculateTotalPrice(modules),
    );

    React.useEffect(() => {
        setModules(modules);
        setTotalPrice(calculateTotalPrice(modules));
    }, [loadingModules]);

    React.useEffect(() => {
        const extractedKeys = modulesState.map((item) => ({
            id: item.id,
            price: item.attributes.price,
            title: item.attributes.name,
            quantity: item.attributes.initialAmount,
            img:
                process.env.REACT_APP_IMG +
                item?.attributes?.imgCard?.data?.attributes?.url,
            characteristics: item.attributes.characteristics,
            submodules: [],
        }));

        submodulesConfigurations.forEach((submodules) => {
            extractedKeys.forEach((el) => {
                if (el.id === submodules.module) {
                    el.submodules.push(submodules);
                }
            });
        });

        setModulesDrawer(extractedKeys);
    }, [modulesState, submodulesConfigurations]);

    function getId(product) {
        console.log(product);
        if (product.attributes.masterId) {
            return product.attributes.masterId
        } else {
            return product.id
        }
    }

    const addProductButton = () => {
        let price;

        if (profile === "profile") {
            price = (
                product?.attributes?.price *
                ((100 - product?.attributes?.PersonalAccountDiscountPercent) / 100)
            ).toFixed(0);
        } else {
            price = product?.attributes?.price;
        }
        let img = process.env.REACT_APP_IMG + product?.attributes?.img_card?.data?.attributes?.url
        try {

            if (product?.attributes?.[`img_color_${currentSetPhoto}`]) {
                const tmp = product?.attributes?.[`img_color_${currentSetPhoto}`].data[0].attributes.url;
                img = process.env.REACT_APP_IMG + tmp;

            }
        } catch (error) {

        }


        dispatch(
            addToCart({
                id: getId(product),
                title: product?.attributes?.title,
                description: product?.attributes?.description,
                img: img,
                price:
                    profile === "profile"
                        ? (
                            product?.attributes?.price + totalPrice + +calculateTotalPriceModules() *
                            ((100 - product?.attributes?.PersonalAccountDiscountPercent) /
                                100)
                        ).toFixed(0)
                        : product?.attributes?.price + totalPrice + +calculateTotalPriceModules(),
                quantity: quantity,
                stockQuantity: product?.attributes?.stockQuantity.toString(),
                materialArticle: materialArticle,
                colorArticle: colorArticle,
                modules: modulesDrawer.filter((item) => item.quantity > 0),
                configurations: items,
                color: colorObj,
                material: materialObj,
            }),
        );

        setTimeout(() => {
            setQuantity(1);
            setDekorAdd(false);
        }, 2500);

        setDekorAdd(true);
    };

    const clickOnColor = (index) => {
        setCurrentColor(index);
        //  setCurrentSetPhoto(index + 1);
    };

    const cliickOnColor = (index) => {
        setCurrentMaterial(index);

        if (isTouchDevice) {
            if (materialPreviewIndex === index) {
                setMaterialPreviewIndex(600);
            } else {
                setMaterialPreviewIndex(index);
            }
        }
    };


    const calculateTotalPriceModules = () => {
        return items.reduce((total, item) => total + +item.price, 0);
    };

    const calculateSumModules = (id, price, title, currentValue) => {
        //  console.log(id, price, title, currentValue);
        setItems((prevItems) => {
            const itemIndex = prevItems.findIndex((item) => item.id == id);
            setTimeout(() => {
                setUpdateWish(updateWish + 1)
            }, 100);
            if (itemIndex !== -1) {
                // Jeśli produkt z takim id już istnieje, aktualizujemy cenę
                const updatedItems = [...prevItems];
                let obj = {...updatedItems[itemIndex]};
                obj.price = price;
                obj.value = currentValue;
                updatedItems[itemIndex] = obj;
                //  console.log(currentValue);
                return updatedItems;
            } else {
                // W przeciwnym razie dodajemy nowy produkt
                const newItem = {
                    id: id,
                    price: price,
                    title: title,
                    value: currentValue,
                };

                //  console.log(currentValue);
                return [...prevItems, newItem];
            }

        });
    };


    function createWishObj(wishId) {
        const random = new Date().getTime()
        let img = '';
        try {
            img = product?.attributes?.[`img_color_${currentSetPhoto}`]?.data[0].attributes?.url
        } catch (error) {
            img = product?.attributes?.img_card?.data?.attributes?.url
        }
        const tmp = {
            uniqId: wishId && wishId.toString().length > 4 ? wishId : random,
            id: getId(product),
            attributes: {masterId: getId(product)},
            title: product?.attributes?.title,
            img: img,
            price:
                profile === "profile"
                    ? (
                        (modules.length > 0 && !loadingModules
                            ? totalPrice + calculateTotalPriceModules()
                            : product?.attributes?.price
                                ? product?.attributes?.price + calculateTotalPriceModules()
                                : 0) *
                        ((100 - product?.attributes?.PersonalAccountDiscountPercent) /
                            100)
                    ).toFixed(0)
                    : modules.length > 0 && !loadingModules
                        ? totalPrice + calculateTotalPriceModules()
                        : product?.attributes?.price
                            ? product?.attributes?.price + calculateTotalPriceModules()
                            : 0,
            quantity: quantity,
            stockQuantity: product?.attributes?.stockQuantity.toString(),
            materialArticle: materialArticle,
            colorArticle: colorArticle,
            modules: modulesDrawer.filter((item) => item.quantity > 0),
            configurations: items,
            color: colorObj,
            material: materialObj,
        }
        //  console.log(tmp);
        return tmp
    }

    function addToWishCard() {
        if (wishId && wishId.toString().length > 5) {

            dispatch(
                removeFromFavorite(wishId)
            )
            navigate('/')
        } else {
            const obj = createWishObj()
            dispatch(
                addToFavorite(obj),
            )
            setTimeout(() => {
                setDekorAdd_fav(false);
            }, 2500);

            setDekorAdd_fav(true);
        }

        //  setTextFavorite(!textFavorite);

    }


    useEffect(() => {
        if (product) {

            setColorsIdArr(
                product?.attributes?.colors?.data.map((color) => color.id),
            );
            setMaterialsIdArr(
                product?.attributes?.materials?.data.map((materials) => materials.id),
            );

            setCollectionId(product?.attributes?.collection?.data?.attributes?.url);

            setCurrentCategories((prevState) => {
                const updatedCategories = [...prevState];
                updatedCategories.splice(
                    3,
                    3,
                    product?.id,
                    product?.attributes?.categories?.data,
                    product?.attributes?.subcategories?.data,
                );
                return updatedCategories;
            });
        }
    }, [product]);

    useEffect(() => {
        collectionData();
    }, [collectionId]);

    useEffect(() => {
        fetchData();
    }, [colorsIdArr]);

    useEffect(() => {
        materialsData();
    }, [materialsIdArr]);

    useEffect(() => {
        simularData();
    }, [currentCategories]);

    //characteristic
    let characteristic = "";

    if (product?.attributes?.characteristics) {
        characteristic = product?.attributes?.characteristics
            .split(";")
            .map((item, index) => <li key={index}>{item.trim()}</li>);
    }

    const products = useSelector((state) => state.cart.products);
    return (
        text[0] &&
        product && (
            <div className="content">
                <Helmet>
                    <title>{product?.attributes?.title}</title>
                    <meta property="og:title" content={product?.attributes?.title}/>

                    <meta name="description" content={product?.attributes?.description}/>
                    <meta
                        property="og:description"
                        content={product?.attributes?.description}
                    />

                    <meta name="keywords" content={seo[0]?.attributes?.keywords}/>

                    <link rel="canonical" href={canonicalUrl}/>
                    <meta name="Publisher" content="https://shadeko.eu/"/>
                </Helmet>
                <ScrollToTop/>
                <div className="product__bread-container">
                    <div className="product__bread-crumbs d-flex">
                        <Link to="/">{text[0]?.attributes?.breadCrumbHome}</Link>
                        <span> / </span>
                        <Link
                            to={"/" + product?.attributes?.categories?.data[0].attributes.url}
                        >
                            {product?.attributes?.categories?.data[0].attributes.name}
                        </Link>
                        <span> / </span>
                        <Link
                            to={
                                "/" + product?.attributes?.subcategories?.data[0].attributes.breadCrumb
                            }
                        >
                            {product?.attributes?.subcategories?.data[0].attributes.title}
                        </Link>
                        <span> / </span>
                        <p>{product?.attributes?.title}</p>

                    </div>
                    <PrevNextProduct text={text} product={product}/>

                </div>
                <div className="product__net">
                    <div className="product__net-img">
                        <div className="product__img-wr">
                            {typeof product?.attributes?.PersonalAccountDiscountPercent ===
                                "number" &&
                                profile === "profile" && (
                                    <>
                                        <div className="account__discount"></div>
                                        <span className="account__discount-text">
                                          -{product?.attributes?.PersonalAccountDiscountPercent}%
                                        </span>
                                    </>
                                )}

                            {/* main photo  */}

                            <div className="swiper-container swiper-single">
                                <div className="swiper-wrapper">

                                    {errorProduct ? (
                                        "error on server"
                                    ) : loadingProduct ? (
                                        <div className="skeletonMainSlider"></div>
                                    ) : (
                                        product?.attributes?.[
                                            `img_color_${currentSetPhoto}`
                                            ]?.data.map((el, index) => (
                                            <div
                                                key={index + "slider-images" + el.id}
                                                className="swiper-slide"
                                            >
                                                <div className="swiper-slide__wr">
                                                    <div
                                                        className={
                                                            skeletonMainSliderState
                                                                ? "skeleton-load-img z1 active"
                                                                : "skeleton-load-img z1"
                                                        }
                                                    ></div>

                                                    <ImageTest
                                                        src={
                                                            el?.attributes?.url
                                                        }
                                                        alt=" "
                                                        onClick={() => {
                                                            openLightBox(index)

                                                        }}
                                                        onLoad={() =>

                                                            index === 0 && setSkeletonMainSliderState(false)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                            <div className="arrow__previous">
                                <img
                                    src={
                                        process.env.PUBLIC_URL + "/img/product-single__arrow.svg"
                                    }
                                    alt="arrow"
                                />
                            </div>
                            <div className="arrow__next">
                                <img
                                    src={
                                        process.env.PUBLIC_URL + "/img/product-single__arrow.svg"
                                    }
                                    alt="arrow"
                                />
                            </div>
                        </div>

                        {/* preview ///////////// */}

                        <div className="swiper-container swiper-miniature">
                            <div className="swiper-wrapper">
                                {errorProduct ? (
                                    "error on server"
                                ) : loadingProduct ? (
                                    <>
                                        <div className="skeleton-preview"></div>
                                        <div className="skeleton-preview"></div>
                                        <div className="skeleton-preview"></div>
                                        <div className="skeleton-preview"></div>
                                        <div className="skeleton-preview"></div>
                                        <div className="skeleton-preview"></div>
                                    </>
                                ) : (
                                    product?.attributes?.[
                                        `img_color_${currentSetPhoto}`
                                        ]?.data.map((el, index) => (
                                        <div
                                            key={index + "slider-images-preview" + el.id}
                                            className="swiper-slide"
                                        >
                                            <div
                                                key={el.id + "img_set"}
                                                className="product__preview-wr"
                                            >
                                                <div className="shadow"></div>
                                                <div
                                                    className={
                                                        skeletonPreviewSliderState
                                                            ? "skeleton-load-img active"
                                                            : "skeleton-load-img"
                                                    }
                                                ><img className={'skeletonImgMedium'} src={'/img/header__logo-short.png'}/></div>

                                                <img
                                                    className="preview"
                                                    src={process.env.REACT_APP_IMG + el?.attributes?.url.replace("/uploads/", "/uploads/small_")}
                                                    alt="product"
                                                    onLoad={() =>
                                                        index ===
                                                        product?.attributes?.[
                                                            `img_color_${currentSetPhoto}`
                                                            ]?.data?.length -
                                                        1 && setSkeletonPreviewSliderState(false)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="product__net-text">
                        <h2>{product?.attributes?.title}</h2>

                        {/* ///price /////////////////////						 */}

                        {profile === "profile" ? (
                            <h3 className="price">

                                {changeCurrency(
                                    product?.attributes?.price + totalPrice + +calculateTotalPriceModules() *
                                    ((100 - product?.attributes?.PersonalAccountDiscountPercent) /
                                        100)
                                    , currency)} s DPH


                                {/*{changeCurrency(*/}
                                {/*    (modules.length > 0 && !loadingModules*/}
                                {/*        ? totalPrice*/}
                                {/*            ? totalPrice + +calculateTotalPriceModules()*/}
                                {/*            : "0"*/}
                                {/*        : product?.attributes?.price*/}
                                {/*            ? product?.attributes?.price +*/}
                                {/*            +calculateTotalPriceModules()*/}
                                {/*            : "0") **/}
                                {/*    ((100 - product?.attributes?.PersonalAccountDiscountPercent) /*/}
                                {/*        100)*/}
                                {/*    , currency)} s DPH*/}

                            </h3>
                        ) : (
                            <h3 className="price">
                                {changeCurrency(product?.attributes?.price + totalPrice + +calculateTotalPriceModules(), currency)} s DPH
                                {/*{modules.length > 0 && !loadingModules*/}
                                {/*    ? totalPrice*/}
                                {/*        ? changeCurrency(product?.attributes?.price + totalPrice + +calculateTotalPriceModules(), currency)*/}
                                {/*        : "0"*/}
                                {/*    : product?.attributes?.price*/}
                                {/*        ? changeCurrency(product?.attributes?.price + +calculateTotalPriceModules(), currency)*/}
                                {/*        : "0"} s DPH*/}
                            </h3>
                        )}

                        <p className="product__p">{product?.attributes?.description}</p>

                        <section className="product__color">
                            {colorsApi.length > 0 && (
                                <h4>{text[0]?.attributes?.productSingleTitleColor}</h4>
                            )}

                            {colorsLoading ? (
                                <div
                                    className={
                                        colorsApi.length > 0
                                            ? "product__color-net"
                                            : "product__color-net m0"
                                    }
                                >
                                    {colorsApi.map((item, index) => (
                                        <div
                                            key={index + "color" + item.id}
                                            className="material__wr"
                                        >
                                            <div
                                                className={
                                                    colorPreviewIndex === index
                                                        ? "material__wr-preview active"
                                                        : "material__wr-preview"
                                                }
                                            >
                                                <h6>{item?.attributes?.article}</h6>

                                                <img
                                                    key={item + index}
                                                    onClick={() => {
                                                        setCurrentMaterial(index);
                                                    }}
                                                    src={
                                                        process.env.REACT_APP_IMG +
                                                        item?.attributes?.img?.data?.attributes?.url
                                                    }
                                                    alt="material"
                                                    className={
                                                        currentColor == index
                                                            ? "material active"
                                                            : "material"
                                                    }
                                                />
                                            </div>

                                            <img
                                                key={item + index}
                                                onMouseOut={() => {
                                                    setColorPreviewIndex(600);
                                                }}
                                                onMouseEnter={() => {
                                                    setColorPreviewIndex(index);
                                                }}
                                                onClick={(e) => {

                                                    clickOnColor(index);

                                                    setColorArticle(e.target.dataset.article);
                                                    changeCurrentSetColor(index);
                                                    setColorObj({
                                                        img: e.target.src,
                                                        name: e.target.dataset.name,
                                                    });
                                                    setUpdateWish(updateWish + 1)
                                                }}
                                                src={
                                                    process.env.REACT_APP_IMG +
                                                    item?.attributes?.img?.data?.attributes?.url
                                                }
                                                alt="color"
                                                className={
                                                    currentColor == index ? "color active" : "color"
                                                }
                                                data-article={item?.attributes?.article}
                                                data-name={item?.attributes?.name}
                                            />
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="skeleton-curcle">
                                    <Skeleton/>
                                    <Skeleton/>
                                    <Skeleton/>
                                </div>
                            )}
                        </section>

                        {/* materials //////////////////////// */}

                        <section className="product__material">
                            {materialsApi.length > 0 && (
                                <h4>{text[0]?.attributes?.productSingleTitleMaterial}</h4>
                            )}

                            <div
                                className={
                                    materialsApi.length > 0
                                        ? "product__material-net"
                                        : "product__material-net m0"
                                }
                            >
                                {materialsLoading ? (
                                    materialsApi.map((item, index) => (
                                        <div
                                            key={index + "material" + item.id}
                                            className="material__wr"
                                        >
                                            <div
                                                className={
                                                    materialPreviewIndex.toString() === index.toString()
                                                        ? "material__wr-preview active"
                                                        : "material__wr-preview"
                                                }
                                            >
                                                <h6>{item?.attributes?.name}</h6>
                                                <h6>{item?.attributes?.article}</h6>

                                                <img
                                                    key={item + index}
                                                    onClick={() => {
                                                        setCurrentMaterial(index);
                                                    }}
                                                    src={
                                                        process.env.REACT_APP_IMG +
                                                        item?.attributes?.img?.data?.attributes?.url
                                                    }
                                                    alt=" "
                                                    className={
                                                        currentMaterial.toString() == index.toString()
                                                            ? "material active"
                                                            : "material"
                                                    }
                                                />

                                            </div>

                                            <img
                                                onMouseOut={() => {
                                                    setMaterialPreviewIndex(600);
                                                }}
                                                onMouseEnter={() => {
                                                    setMaterialPreviewIndex(index);
                                                }}
                                                key={item + index + "material"}
                                                onClick={(e) => {
                                                    cliickOnColor(index);
                                                    changeCurrentSetMaterial(index);
                                                    setMaterialArticle(e.target.dataset.article);

                                                    setMaterialObj({
                                                        img: e.target.src,
                                                        name: e.target.dataset.name,
                                                    });
                                                    setUpdateWish(updateWish + 1)
                                                }}
                                                src={
                                                    process.env.REACT_APP_IMG +
                                                    item?.attributes?.img?.data?.attributes?.url
                                                }
                                                alt=" "
                                                className={
                                                    //  currentMaterial == index
                                                    materialArticle == item?.attributes?.article
                                                        ? "material active"
                                                        : "material"
                                                }
                                                data-article={item?.attributes?.article}
                                                data-name={item?.attributes?.name}
                                            />

                                        </div>
                                    ))
                                ) : (
                                    <div className="">
                                        <div className="w100">
                                            <h4>{text[0]?.attributes?.productSingleTitleMaterial}</h4>
                                        </div>

                                        <div className="skeleton-curcle">
                                            <Skeleton/>
                                            <Skeleton/>
                                            <Skeleton/>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </section>

                        <div className="max60">

                            {errorProduct ? (
                                "error on server"
                            ) : loadingProduct ? (
                                <div className="skeleton-configuration">
                                    <Skeleton/>
                                    <Skeleton/>
                                </div>
                            ) : (

                                product?.attributes?.configurations?.data
                                    .sort((a, b) => {
                                        // Визначте бажаний порядок сортування за типом (color, short, long)
                                        const order = ["color", "short", "long"];
                                        return (
                                            order.indexOf(a.attributes.type) -
                                            order.indexOf(b.attributes.type)
                                        );
                                    })
                                    .map((el, i) => {
                                        switch (el.attributes.type) {
                                            case "color":
                                                return (

                                                    <ConfiguratorElementColor
                                                        id={el.id}
                                                        wishId={wishId}
                                                        title={el.attributes.name}
                                                        arr={el.attributes.value}
                                                        key={i + "configuration-color" + el.id}
                                                    />
                                                );
                                            case "short":
                                                return (
                                                    <ConfiguratorElementShort
                                                        calculateSumModules={calculateSumModules}
                                                        id={el.id}
                                                        wishId={wishId}
                                                        title={el.attributes.name}
                                                        arr={el.attributes.value}
                                                        key={i + "configuration-short" + el.id}
                                                    />
                                                );
                                            case "long":
                                                return (
                                                    <ConfiguratorElementLong
                                                        calculateSumModules={calculateSumModules}
                                                        id={el.id} wishId={wishId}
                                                        def={el.attributes.name}
                                                        arr={el.attributes.value}
                                                        key={i + "configuration-long" + el.id}
                                                    />
                                                );
                                            default:
                                                return null; // Можливо, якщо тип не відповідає жодному з визначених варіантів.
                                        }
                                    })
                            )}
                        </div>

                        {/* quantity	///////////////// */}

                        <section className="product__quantity">
                            <h4>{text[0]?.attributes?.modalCartQuantity}</h4>

                            <div className="product__quantity-net di-flex a-i-center">
                                <div className="miniature__wr">
                                    <div
                                        className={
                                            skeletonQuantityState
                                                ? "skeleton-load-img active"
                                                : "skeleton-load-img"
                                        }
                                    ><img src={'/img/header__logo-short.png'}/></div>

                                    <img
                                        className="miniature"
                                        src={
                                            process.env.REACT_APP_IMG +
                                            product?.attributes?.[`img_color_${currentSetPhoto}`]
                                                ?.data[0]?.attributes?.url
                                        }
                                        alt=" "
                                        onLoad={() => {
                                            setSkeletonQuantityState(false);
                                        }}
                                    />
                                </div>

                                <div className="product__quantity-counter d-flex a-i-center ">
                                    <p
                                        onClick={() => {
                                            setQuantity((prev) => (prev > 1 ? prev - 1 : prev));
                                            setUpdateWish(updateWish + 1)
                                        }}
                                        className="quantity__arrow-mobile minus-mobile"
                                    >
                                        -
                                    </p>
                                    <img
                                        onClick={() => {
                                            setQuantity((prev) => (prev > 1 ? prev - 1 : prev));
                                            setUpdateWish(updateWish + 1)
                                        }}
                                        className="quantity__arrow quantity__arrow-prev"
                                        src={process.env.PUBLIC_URL + "/img/quantity__arrow.svg"}
                                        alt="arrow"
                                    />
                                    <p>{quantity}</p>
                                    <img
                                        onClick={() => {
                                            setQuantity((prev) => prev + 1)
                                            setUpdateWish(updateWish + 1)
                                        }}
                                        className="next quantity__arrow"
                                        src={process.env.PUBLIC_URL + "/img/quantity__arrow.svg"}
                                        alt="arrow"
                                    />

                                    <p
                                        onClick={() => {
                                            setQuantity((prev) => prev + 1)
                                            setUpdateWish(updateWish + 1)
                                        }}
                                        className="quantity__arrow-mobile"
                                    >
                                        +
                                    </p>
                                </div>
                            </div>

                            <div className="modules">
                                {loadingModules ? (
                                    <div className="skeleton-modules">
                                        <Skeleton/>
                                        <Skeleton/>
                                    </div>
                                ) : (

                                    !moduleLock && modules.map((el, i) => (
                                        <SingleModule
                                            module={el}
                                            wishId={wishId}
                                            key={el.id + "module-part" + i}
                                            onChange={handleModuleChange}
                                            CollectionModuleConfig={CollectionModuleConfig}
                                            moduleLength={modules.length}
                                        />
                                    ))
                                )}
                            </div>

                            <div className="product__buttons">
                                <div className="addToDrawer__wr">
                                  <span
                                      className={
                                          dekorAdd
                                              ? "addToDrawerDekor active dekor1"
                                              : "addToDrawerDekor dekor1"
                                      }
                                  >
                                    +{quantity}
                                      </span>
                                    <span
                                        className={
                                            dekorAdd
                                                ? "addToDrawerDekor active dekor2"
                                                : "addToDrawerDekor dekor2"
                                        }
                                    >
                                            +{quantity}
                                      </span>
                                    <span
                                        className={
                                            dekorAdd
                                                ? "addToDrawerDekor active dekor3"
                                                : "addToDrawerDekor dekor3"
                                        }
                                    >
                                    +{quantity}
                                  </span>
                                    <div className={"products_half"}>


                                        <button
                                            style={{position: 'relative'}}
                                            onClick={() => {
                                                addToWishCard()
                                            }}
                                            className={" upper  " + (isInCart ? "checkOut" : "addToCard")}
                                        >
                                                  <span
                                                      className={
                                                          dekorAdd_fav
                                                              ? "addToDrawerDekor active dekor1"
                                                              : "addToDrawerDekor dekor1"
                                                      }
                                                  >
                                                    +{quantity}
                                                      </span>
                                            <span
                                                className={
                                                    dekorAdd_fav
                                                        ? "addToDrawerDekor active dekor2"
                                                        : "addToDrawerDekor dekor2"
                                                }
                                            >
                                                            +{quantity}
                                                      </span>
                                            <span
                                                className={
                                                    dekorAdd_fav
                                                        ? "addToDrawerDekor active dekor3"
                                                        : "addToDrawerDekor dekor3"
                                                }
                                            >
                                                    +{quantity}
                                                  </span>
                                            {isInCart ? text[0]?.attributes?.deleteFavorite : text[0]?.attributes?.buttonAddToFavorite}
                                        </button>


                                        <button
                                            onClick={() => {
                                                addProductButton();
                                            }}
                                            className="addToCard upper halfButton"
                                        >
                                            {text[0]?.attributes?.buttonAddToCart}
                                        </button>
                                    </div>


                                </div>

                                <Link to={products.length === 0 ? "#" : "/checkout"}
                                      className={"checkOut upper " + (products.length === 0 ? " disabledLink" +
                                          " " : "")}>
                                    {text[0]?.attributes?.modalCartButtonSecond}
                                </Link>
                            </div>

                            {characteristic && (
                                <div className="description__wr">
                                    <h4>{text[0]?.attributes?.productSingleTitleDescription}</h4>

                                    <ul className="description__list">{characteristic}</ul>
                                </div>
                            )}
                        </section>
                    </div>
                </div>

                {collectionApi.length > 1 && (
                    <div className="product__similar">
                        <h3></h3>
                        <h3>{text[0]?.attributes?.productSingleTitleFromColection}</h3>
                        <div className="product__similar-net">
                            {collectionApi.map(
                                (collection, i) =>
                                    collection.id !== +id && (
                                        <Card
                                            key={collection.id + "collectionProducts" + i}
                                            product={collection}
                                            type="catalog"
                                            addCart={text[0]?.attributes?.buttonAddToCart}
                                            addFavorite={text[0]?.attributes?.buttonAddToFavorite}
                                            deleteFavorite={text[0]?.attributes?.deleteFavorite}
                                        />
                                    ),
                            )}
                        </div>
                    </div>
                )}

                {similarApi.length > 0 && (
                    <div className="product__similar">
                        <h3>{text[0]?.attributes?.productSingleTitleSimularProducts}</h3>

                        <div className="product__similar-net">
                            {similarApi.map((simular, i) => (
                                <Card
                                    key={simular.id + "collectionProducts" + i}
                                    product={simular}
                                    type="catalog"
                                    addCart={text[0]?.attributes?.buttonAddToCart}
                                    addFavorite={text[0]?.attributes?.buttonAddToFavorite}
                                    deleteFavorite={text[0]?.attributes?.deleteFavorite}
                                />
                            ))}
                        </div>
                    </div>
                )}

                <Galery
                    images={product?.attributes?.[`img_color_${currentSetPhoto}`]?.data}
                    closeLightbox={closeLightbox}
                    lightboxOpen={lightboxOpen}
                    startIndex={startIndex}
                />
            </div>
        )
    );
}

export default ProductSingle;
