import {createSlice} from '@reduxjs/toolkit';

export const favoriteSlice = createSlice({
    name: 'favorite',
    initialState: {
        favorite: []
    },
    reducers: {
        addToFavorite: (state, action) => {
            const itemIndex = state.favorite.findIndex(item => item.uniqId === action.payload.uniqId);
            if (itemIndex !== -1) {
                state.favorite.splice(itemIndex, 1);
            } else {
                state.favorite.push(action.payload);
            }
        },
        removeFromFavorite: (state, action) => {
            const itemIndex = state.favorite.findIndex(item => item.uniqId === action.payload);
            console.log(itemIndex);
            if (itemIndex !== -1) {
                state.favorite.splice(itemIndex, 1);
            }
        },
        updateInFavorite(state, action) {
            const itemIndex = state.favorite.findIndex(item => item.uniqId == action.payload.uniqId);
            if (itemIndex !== -1) {

                state.favorite[itemIndex] = action.payload
            }

        }
    }
});

export const {addToFavorite, removeFromFavorite, updateInFavorite} = favoriteSlice.actions;

export default favoriteSlice.reducer;
