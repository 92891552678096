import React from 'react';
import './ModalSuccessPayment.scss';
import { useDispatch, useSelector } from 'react-redux';
import { resetCart } from '../../Redux/cartReducer';
import useFetch from '../../Hooks/useFetch'
import CreatePDFforMail from '../../Components/CreatePDFforMail'

function ModalSuccessPayment() {

	const [copied, setCopied] = React.useState(false);
	const [successParam, setSuccessParam] = React.useState(null);
	const [emailParam, setEmailParam] = React.useState(null);
    const languageInformation = useSelector(state => state.language.language);

    const {data, loading, error}= useFetch(process.env.REACT_APP_API_URL+`/successful-orders-creation?locale=${languageInformation.language}&populate=*`);
    const dispatch = useDispatch();
	

	React.useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		setSuccessParam(queryParams.get('success'));
		setEmailParam(queryParams.get('email'));
	 }, []);


	 const isSuccess = successParam !== null && successParam !== '' && successParam !== 'false';
	 const isFail =  successParam !== null && successParam !== '' && successParam === 'false';

     React.useEffect(()=>{   
            isSuccess && dispatch(resetCart());
     }, [isSuccess])

	 const [openModal, setOpenModal] = React.useState(isSuccess);

	 React.useEffect(() => {
		setTimeout(()=>{
			setOpenModal(isSuccess); 
		})
	// Встановлюємо openModal залежно від isSuccess
	 }, [isSuccess]);

	const textToCopy = successParam;


	const handleCopy = () => {
		navigator.clipboard.writeText(textToCopy)
		  .then(() => {
			 setCopied(true);
			 setTimeout(() => setCopied(false), 1500);
		  })
		  .catch(error => console.error('Помилка при копіюванні:', error));
	 };




	return (
		<div className={(openModal && data[0]?.attributes?.modalTitle) ? 'modal__wr active modal__successPayment' : 'modal__wr modal__successPayment'}>

            {
               isSuccess && <CreatePDFforMail text={data} email={emailParam} />
            }
            
            

			<div className="modal__body">

				<div onClick={()=> setOpenModal(!openModal)} className="modal__close"></div>


				<h4 className='cen'>{data[0]?.attributes?.modalTitle}</h4>
				<h6>{data[0]?.attributes?.modalParagraph1} <b>{emailParam}</b>.</h6>


				<div className='d-flex a-i-center order__number'>
					<h6>{data[0]?.attributes?.modalParagraph2} <b className={copied ? 'copy' : ''}>{successParam}</b></h6>
					<img onClick={handleCopy}  src={process.env.PUBLIC_URL + '/img/copy-button.svg'} alt="copy button" />
				</div>
				

				<h6>{data[0]?.attributes?.modalParagraph3}</h6>
				<h6>{data[0]?.attributes?.modalParagraph4}</h6>


			</div>
		</div>
	);
}

export default ModalSuccessPayment;
