import "./catalog.scss";
import {useSelector} from "react-redux";
import PcNav from "./components/pcNav";
import {useState} from "react";
import PcHelmet from "./components/pcHelmet";
import Catalog from "../../Components/Catalog";
import PcCatalog from "./components/pcCatalog/pcCatalog";
import useFetch from "../../Hooks/useFetch";
import {useSearchParams} from 'react-router-dom';

function ProductsCatalog({text}) {
    const [categoryOpen, setCategoryOpen] = useState(true);
    const [filtersOpen, setFiltersOpen] = useState(false);
    const languageInformation = useSelector((state) => state.language.language);
    const [searchParams, setSearchParams] = useSearchParams();
    const categoryID = searchParams.get('category');

    const {data, loading, error} = useFetch(
        process.env.REACT_APP_API_URL +
        `/categories?filters[masterId]=${categoryID}&locale=${languageInformation.language}&populate[subcategories][populate][0]=img&populate[categoriesInCategories][populate][2]=img&populate[collectionsImg][populate][3]=img`,
    );

    return (
        <>
            <PcHelmet text={text}/>
            <div>
                <PcNav
                    home={false}
                    data={data}
                    text={text}
                    setFiltersOpen={setFiltersOpen}
                    setCategoryOpen={setCategoryOpen}
                    categoryOpen={categoryOpen}
                    filtersOpen={filtersOpen}
                />
                <div className={'navTopSpacer'}></div>
                <PcCatalog data={data} loading={loading} error={error} categoryOpen={categoryOpen} text={text}/>
                <Catalog
                    filtersOpen={filtersOpen}
                    setFiltersOpen={setFiltersOpen}
                    setCategoryOpen={setCategoryOpen}
                    categoryOpen={categoryOpen}
                    text={text}
                    title={data[0] ? data[0].attributes.allProductsText : ''}
                    filterDefault={text[0]?.attributes?.breadCrumbCategory1}
                    block={true}
                    preCategory={"Sofa"}
                    preSubCategoryIgnore={true}
                    filterExp="&filters[categories][url][$containsi]=Sofas"
                    categoryUrlSortForSubcategory={"sofas"}
                    filterOpenState={filtersOpen}
                />
            </div>
        </>
    );
}

export default ProductsCatalog;
