import {useSearchParams,} from "react-router-dom";
import {useEffect} from "react";

const Pagination = ({currentPage, totalPages, onPageChange, currentPageButton}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const handlePageChange = (page) => {
        onPageChange(page);
        const newParams = new URLSearchParams(searchParams);
        newParams.set('page', page);
        setSearchParams(newParams);
    };

    useEffect(() => {
        if (currentPage == 1) {
            const newParams = new URLSearchParams(searchParams);
            newParams.delete('page');
            setSearchParams(newParams);
        }
    }, [currentPage]);


    const generatePageNumbers = () => {
        const pagesToShow = 5; // Кількість елементів пагінації
        const ellipsis = '...';

        if (totalPages <= 1) {
            // Якщо сторінок одна або менше, повертаємо пустий масив
            return [];
        }

        const middlePages = Math.floor((pagesToShow - 1) / 2);
        const firstPage = Math.max(1, currentPage - middlePages);
        const lastPage = Math.min(currentPage + middlePages, totalPages);

        let pages = [];

        if (firstPage > 1) {
            pages.push(1);
            if (firstPage > 2) {
                pages.push(ellipsis);
            }
        }

        for (let i = firstPage; i <= lastPage; i++) {
            pages.push(i);
        }

        if (lastPage < totalPages) {
            if (lastPage < totalPages - 1) {
                pages.push(ellipsis);
            }
            pages.push(totalPages);
        }

        return pages;
    };

    const renderPageNumbers = () => {
        const pages = generatePageNumbers();

        return pages.map((page, index) => {
            if (page === '...') {
                return (
                    <button key={index} className="catalog__pagination-item dots">
                        {page}
                    </button>
                );
            }

            return (
                <button
                    key={index}
                    className={(currentPageButton === 1 && page === currentPage || (currentPageButton !== 1 && page === currentPageButton)) ? 'catalog__pagination-item active' : 'catalog__pagination-item'}
                    onClick={() => handlePageChange(page)}
                    disabled={(currentPageButton === 1 && page === currentPage || (currentPageButton !== 1 && page === currentPageButton))}
                >
                    {page}
                </button>
            );
        });
    };

    return (
        <div className="pagination">
            {renderPageNumbers()}
        </div>
    );
};

export default Pagination;
