import React from "react";


function CatalogFiler(props) {


    return (
        <div className={props.filtersOpen && !props.home ? 'filterContainerOpen open' : 'filterContainerOpen'}></div>
    )

}

export default CatalogFiler;
