import React, {useRef} from 'react';
import useFetch from '../../Hooks/useFetch';
import {Link} from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import { useSelector,useDispatch } from 'react-redux'
import { clearUser } from '../../Redux/adminUserReducer';


function CalculateCostDelivery() {

    const navigate = useNavigate()

    
    const [currentPage,setCurrentPage] = React.useState(1);
    const [orders, setOrders] = React.useState([]);


    const { data, loading, error,pagination } =
    useFetch(`${process.env.REACT_APP_API_URL}/calculate-shippings?pagination[page]=${currentPage}&pagination[pageSize]=9&populate=*`);

    const userAuthorization = useSelector(state => state.adminUser.user);
    const dispatch = useDispatch()

    const checkAuthorization = () => {
        userAuthorization.length === 0  && navigate('/admin-panel')
    }

    const logOut = () =>{
        dispatch(clearUser())
        navigate('/admin-panel')
    }

    React.useEffect(()=>{
        checkAuthorization();
    },[]);

    const componentRef = useRef(null);

    React.useEffect(() => {
        if (componentRef.current) {
          componentRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, []);




    React.useEffect(() => {
        if (!loading && !error && data) {
            setOrders((prevOrders) => [...prevOrders, ...data]);
            
            if(componentRef.current && !loading && currentPage !== 1){
            setTimeout(()=>{

               (componentRef && componentRef.current) && componentRef.current.scrollIntoView({ behavior: 'smooth' });
            },0)
        }

        }
      }, [data, loading, error]);





    return (
        !loading && <div className="admin-panel__body">
            

            <div  className="admin-panel__order hover-none">
                            <div className='timestamp fw500'>  
                                 ID
                            </div>
                            <div className="payment-status cen fw500">Ім'я:</div>
                            <div className="payment-status  cen fw500">Номер телефону:</div>
                            <div className="payment-status  cen fw500">Країна:</div>
                            <div className="payment-status  cen fw500">Місто:</div>
                            <div className="payment-status  cen fw500 br-none">Статус:</div>
            </div>


            {
            orders?.map((e,i)=>
            
            <Link key={i+'calculate-cost-delivery'+e.id} to={"https://shadeko.eu/admin-panel-edit-calculate-cost-delivery/" + e.id} className="admin-panel__order">

                  <div className='timestamp'>{e.id}</div>

                    <div className="payment-status cen">{e.attributes.name}</div>

                    <div className="payment-status order-status cen">{e.attributes.phone}</div>

                    <div className="payment-status order-status cen">{e.attributes.country}</div>
                    
                    <div className="payment-status cen order-status mx">{e.attributes.city}</div>

                    <div className="admin-order-totalPrice cen br-none">{e.attributes.status === 'Added' ? 'Додана' : 'Переглянута'  }</div>


            </Link>
)}



            <div ref={componentRef} className="w100 d-flex j-c-center">
                  <button onClick={()=>{setCurrentPage(currentPage + 1)}} className={pagination?.pageCount === currentPage ? 'product__more-button none' : 'product__more-button' }>Показати ще</button>
        </div>

            <Link to='/admin-panel-orders'>
					<img className='catalog-orders-button' src={process.env.PUBLIC_URL + '/img/list-order-admin.svg'} alt="catalog orders" />
			</Link>

               
			<img onClick={() => logOut()}  className='admin-exit' src={process.env.PUBLIC_URL + '/img/exit-admin.svg'} alt="exit" />
        </div>
    );
}

export default CalculateCostDelivery;