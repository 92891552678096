import React from 'react';
import axios from 'axios';


function AdminChangeStatusOrder({ id,editStatusOpen,setEditStatusOpen, currentStatus }) {
 
    
     const [currentStatusOrder,setCurrentStatusOrder] = React.useState(currentStatus)
     const [disableButtonStatus, setDisableButtonStatus] = React.useState(false)
   

    const types = [
        {
            text: 'Очікує на розгляд менеджера',
            data: 'Pending maneger review'
        },
        {
            text: 'Обробка',
            data: 'Processing'
        },
        {
            text: 'Відправлено',
            data: 'Shipped'
        },
        {
            text: 'Доставлено',
            data: 'Delivered'
        },
        {
            text: 'Скасовано',
            data: 'Canceled'
        },
        {
            text: 'Запит на повернення',
            data: 'Return requested'
        },
        {
            text: 'Повернення завершено',
            data: 'Return complited'
        },
    ]


    const changeOptionType = (data) =>{
        setCurrentStatusOrder(data)
    }

    async function changeStatusOnServer(id, option) {

        setDisableButtonStatus(!disableButtonStatus)
        try {
         
          const response = await axios.put(`${process.env.REACT_APP_API_URL}/unpaid-orders/${id}?populate=*`,
          {
          data: {
            status: option, 
          
          },
        },
         {
             headers: {
                 Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
              },
         });
         


      
          if (response.status === 200) {
            
            setEditStatusOpen(!editStatusOpen)

            setTimeout(()=>{
                window.location.reload();
            },1000)
           

          } else {
            console.log('Статус відповіді не 200: ', response.status);
          }
        } catch (error) {
          console.error('Помилка: ', error);
        }

        setDisableButtonStatus(!disableButtonStatus)
      }
      
    



    return (
        <div onClick={()=>{setEditStatusOpen(!editStatusOpen)}} className={editStatusOpen ? 'modal__wr active editPaidType' : 'modal__wr editPaidType'}>

            <div onClick={(e)=>{e.stopPropagation()}} className="modal__body">
                <h4 className='cen w100'>Змінити статус замовлення:</h4>

                <ul className="types">
                    {
                        types.map((e,i)=>(

                            <div className='d-flex a-i-center'>    
                            <li 
                            onClick={()=>{changeOptionType(e.data)}} 
                            key={'type-paid-admin-panel'+i} 
                            className={currentStatusOrder === e.data ? 'active' : ''} 
                            data-type={e.data}>{e.text}</li>
                            </div>
                        ))
                    }
                </ul>

                  <div className='d-flex j-c-center w100'>
                     <button 
                     onClick={()=>{changeStatusOnServer(id,currentStatusOrder)}} 
                     className='search__button'
                     disabled={disableButtonStatus}
                     >Підтвердити</button>
                  </div>   
                


            </div>

        </div>

    );
}

export default AdminChangeStatusOrder;