import './selectCurrency.scss';
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {changeLanguageCurrency} from "../../Redux/languageReducer";


function SelectCurrency() {
    const [currencyText, setCurrencyText] = useState('')
    const languageInformation = useSelector(state => state.language.language);

    const [currencyOpen, setCurrencyOpen] = useState(false);

    const currencyFromState = useSelector(state => state.language.language.currency)
    const currencies = useSelector(state => state.language.language.currencies)
    const dispatch = useDispatch()


    function selectCurrency(currency) {
        setCurrencyText(currency[0]);
        setCurrencyOpen(false);
        dispatch(changeLanguageCurrency(currency))

    }

    function display(value) {
        try {

            if (value.toUpperCase() === "CZ") return "Kč"
        } catch {
            return '-'
        }

        return value
    }

    useEffect(() => {
        setCurrencyText(currencyFromState)
    }, [currencyFromState])
    if (!currencies) return '---'
    const currencyTag = <div className={currencyOpen ? 'header__language active' : 'header__language'}>

        <div onClick={() => {
            setCurrencyOpen(!currencyOpen)
        }} className="header__language-title d-flex a-i-center">
            <p>{display(currencyText)}</p>
            <img src={process.env.PUBLIC_URL + '/img/language__arrow.svg'} alt="arrow"/>
        </div>
        {
            currencies.length > 0 &&
            <ul className="header__language-body">

                {
                    currencies.filter(currency => {
                        if (!currency.languages) return '-'
                        if (currency.languages === 'all') {
                            return true
                        }
                        return currency.languages.split(',').some(language => {
                            return language.toUpperCase() === languageInformation.language.toUpperCase()
                        })

                    }).map(data => {
                        return <li onClick={(e) => {
                            selectCurrency([data.label, data.currencyForPay])
                        }}><span>{data.label}</span></li>
                    })
                }  </ul>
        }


    </div>


    return (
        <div>{currencyTag}</div>)
}

export default SelectCurrency;
