import React from 'react';
import axios from 'axios';

function SendMailPDF({text, pdfBlob, order, email}) {


    const sendMessangeOnEndpoint = async () => {

        try {

            const formData = new FormData();
            formData.append('name', Date.now().toString());
            formData.append('textPart1', text[0]?.attributes?.pdfText1);
            formData.append('textPart2', text[0]?.attributes?.pdfText2);
            formData.append('textPart3', text[0]?.attributes?.pdfText3);
            formData.append('textPart4', text[0]?.attributes?.pdfText4);
            formData.append('email', email);

            formData.append('pdfFile', pdfBlob, 'shadeko.pdf'); // Додайте Blob як файл до FormData


            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/email-endpoints`,
                formData, // Відправте FormData на бекенд
                {
                    headers: {
                        Authorization: 'Bearer ' + process.env.REACT_APP_API_TOKEN,
                        'Content-Type': 'multipart/form-data', // Встановіть заголовок Content-Type для FormData
                    },
                }
            );

            // Обробка успішного відправлення

        } catch (err) {
            // Обробка помилки
            console.error('Помилка:', err);
        }
    };

    React.useEffect(() => {
        console.log(text[0], pdfBlob, order);
        if (text[0] && pdfBlob && order) {
            sendMessangeOnEndpoint();
        }
    }, [text[0], pdfBlob]);

    return <div></div>;
}

export default SendMailPDF;
