import React from 'react';
import './../ModalSuccessPayment/ModalSuccessPayment.scss';
import {useDispatch, useSelector} from 'react-redux';
import useFetch from '../../Hooks/useFetch'

function ModalFailPayment() {


    const languageInformation = useSelector(state => state.language.language);

    const {
        data,
        loading,
        error
    } = useFetch(process.env.REACT_APP_API_URL + `/the-text-of-the-window-of-fails-order-creations?locale=${languageInformation.language}`);
    const dispatch = useDispatch();


    const [openModal, setOpenModal] = React.useState(true);


    return (
        <div className={(openModal && data[0]?.attributes?.title) ? 'modal__wr active modal__successPayment' : 'modal__wr modal__successPayment'}>
            <div className="modal__body">

                <div onClick={() => setOpenModal(!openModal)} className="modal__close"></div>


                <h4 className='cen'>{data[0]?.attributes?.title}</h4>


            </div>

        </div>
    );
}

export default ModalFailPayment;
