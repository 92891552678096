import React from 'react';
import { Document, Page, Text, View, PDFViewer, StyleSheet, PDFDownloadLink, Font } from '@react-pdf/renderer';
import useFetch  from '../../Hooks/useFetch';
import { useSelector } from 'react-redux';
import axios from 'axios';


Font.register({
    family: "Roboto",
    src:
      "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"
  });
  
  
  const styles = StyleSheet.create({
    wrapper: {
        padding: '10px',
        fontFamily: 'Roboto',
        fontSize: '12px',
        width: '100%',
        
      },

    w100 : {
        width: '100%'
    },

    cen: {
        textAlign: 'center'
    },

    f16: {
        fontSize: '16px'
    },
    mt20: {
        marginTop: '20px',     
    },

    mb20: {
        marginBottom: '20px',
    },

    flex: {
        display: 'flex',
        flexDirection: 'row',
    },

    flex20: {
        flex: '0 0 20%'
    },

    flex80 : {
        flex: '0 0 80%'
    },

    wrap: {
        wrap: 'wrap',
    },
    section: {
        flexGrow: 1,
        flexShrink: 1,
    },

   document__net :{
            border: '2px solid #454547',
            borderRadius: '2px',
            fontSize: '10px',
            borderBottom: 'none',
    },

    document__item:{
         
        display: 'flex',
        flexDirection: 'row',
    },

    document__item_part: {
        paddingTop: '5x',
        paddingBottom: '5px',
        textAlign: 'center'
    },

    bl: {
        borderLeft: '2px solid #454547',
    },

    flex18: {
        flex: '0 0 5%',
        height: '100%',
        display: 'flex',
        justifyContent:'center',
        flexDirection: 'column',
    },

    flex50: {
        flex: '0 0 35%',
        height: '100%',
        display: 'flex',
        justifyContent:'center',
        flexDirection: 'column',
    },
 
    flex12: {
        flex: '0 0 12%',
      
        height: '100%',
        display: 'flex',
        justifyContent:'center',
        flexDirection: 'column',
    },


    bb0:{
        borderBottom: 'none'
    },


    configurationItem: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: "5%",
        borderTop: 'none',
        borderRight: 'none',
        paddingTop: '5px',
        paddingBottom: '5px',
        paddingLeft: '5px',
        borderTop: '2px solid #454547',
        borderLeft: '2px solid #454547',
        gap: '8px',
    },

    bolt_border: {
        borderBottom: '2px solid #454547',
    },

    document__first_item: {
        borderBottom: '2px solid #454547',
    },

    bdR: {
        borderRight: '2px solid #454547',
        paddingTop: '5px',
        paddingBottom: '5px',
        
    },

    bdL: {
        borderLeft: '2px solid #454547',
        paddingTop: '5px',
        paddingBottom: '5px',
        
    },

    p0 :{
        padding: '0px',
        gap: '0px'
    },

    flex35: {
        flex: '0 1 37%',
        width: '100%',
     
    },

    
    flex16: {
         flex: '0 0 12.66%',
        justifyContent: 'center', // Вертикальне центрування
        alignItems: 'center',
        
    },

    flex15: {
        flex: '0 0 12.66%',
        height: '100%',
        display: 'flex',
        justifyContent:'center',
        flexDirection: 'column'
    },

    flex_auto: {
        flex: '1 1 auto'
    },

    padingBase5:{
        paddingLeft: '5px',
        paddingRight: '5px',
    },

    submodules: {
        borderLeft: '2px solid #454547',
        borderTop: '2px solid #454547',
        marginLeft: '5%',
        padding: '5px',    
        fontSize: '9px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        gap: '7px'
    },

    bolt: {
        fontWeight: '900',
        fontSize: '12px',
    }

  })

  function AdminTextualPDF({ order }) {

    const [idsSubmodules, setIdsSubmodules] = React.useState([]);
    const [submodulesArr,setSubmodulesArr] = React.useState([])

    


    React.useEffect(() => {
        if (order && order.attributes.productsDetails) {

            let ids = order.attributes.productsDetails.map((e) => e.modules.map((module) => module.submodules));

          

            ids = ids.map((e2) => e2.map((submodule) => submodule.map((e3)=> e3.submodule )));
           
            let flatArray = ids.flat();
            let flatArray2 = flatArray.reduce((acc, current) => acc.concat(current), []);
            flatArray2 = [...new Set(flatArray2)];
            
            console.log(flatArray2);

            setIdsSubmodules(flatArray2);
        }
      }, []);


      React.useEffect(()=>{

        const requests = idsSubmodules.map((id) =>
        axios.get(`${process.env.REACT_APP_API_URL}/submodules/${id}?populate=*`,{
            headers: {
                Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
            },
        })
      );
  
      // Використовуйте Promise.all для об'єднання запитів
      Promise.all(requests)
        .then((responses) => {
          
          const data = responses.map((response) => response.data.data);
          setSubmodulesArr(data);

        })
        .catch((error) => {
          console.error(error);
        });

      }, [idsSubmodules])



      console.log(submodulesArr);


	const languageInformation = useSelector(state => state.language.language);

  const { data: text, loading: loadingText, error: errorText } = useFetch(`${process.env.REACT_APP_API_URL}/Text-for-pdfs?locale=${languageInformation.language}&populate=*`);

  const currentDate = new Date();

  // Отримуємо день, місяць і рік з поточної дати
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Місяці починаються з 0, тому додаємо 1
  const year = currentDate.getFullYear();

  // Форматуємо день, місяць і рік в строку

  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDate = `${formattedDay}.${formattedMonth}.${year}`;

  const clientInformation = order.attributes.clientInformation;

  return (
    <div>
      <PDFDownloadLink
        document={<MyDocument 
          order={order}
          text={text}
          formattedDate={formattedDate}
          clientInformation={clientInformation}
          submodulesArr={submodulesArr}
           />}
        fileName="shadeko.pdf"
        style={{
          textDecoration: 'none',
          marginBottom: '5px',
          fontSize: '20px'

        }}
      >
        {({ blob, url, loading, error }) =>
          loading ? 'Loading...' : 'Завантажити іформацію про замовлення в PDF TEXT Format'
        }
      </PDFDownloadLink>

      <PDFViewer width="0px" height="0px">

        <MyDocument 
        order={order}
        text={text}
        formattedDate={formattedDate}
        clientInformation={clientInformation}
        submodulesArr={submodulesArr}
         />
         
      </PDFViewer>
    </div>
  );
}



const MyDocument = ({ order,text, formattedDate,clientInformation, submodulesArr }) => (


    

    (text && submodulesArr) &&
    <Document>
      <Page size="A4" style={styles.wrapper}>
  
        <Text style={[styles.w100, styles.cen, styles.f16]}>Видаткова накладна №{order.attributes.timeStamp}</Text>
        <Text style={[styles.w100, styles.cen, styles.f16]}>Видана від {formattedDate}р.</Text>

        <View style={[styles.mt20,styles.mb20, styles.flex]}>
            <Text style={[styles.flex20]}>Постачальник:</Text>
            <View>
                <Text>ТОВ "Shadeko"</Text>
                <Text>р.р 76877686686 у банку "Аваль" м. Львів МФО 300333</Text>
            </View>
        </View>

        <View style={[styles.mb20, styles.flex]}>
            <Text style={[styles.flex20]}>Покупець:</Text>
            <View>         
                <Text>{clientInformation.LastName +' '+clientInformation.Name}</Text>
            </View>
        </View>

        <View style={[styles.mb20, styles.flex]}>
            <Text style={[styles.flex20]}>Договір:</Text>
            <View style={[styles.flex80, styles.wrap]}>         
                <Text style={styles.wrap}>Купівлі-Продажу по трек-номеру {order.attributes.timeStamp} отриманому на інформаційній платформі shadeko.eu</Text>
            </View>
        </View>

        <View style={[styles.document__net]}>

            <View style={[styles.document__item,styles.document__first_item ]}>


                <View style={[styles.document__item_part,styles.flex18]}>
                     <Text>№</Text>
                </View>

               


                <View style={[styles.document__item_part,styles.bl,styles.flex50]}>
                   <Text>Товар</Text>
                </View>

                

                <View style={[styles.document__item_part,styles.bl,styles.flex12]}>
                   <Text>Кіль-ть</Text>
                </View>

                


                <View style={[styles.document__item_part,styles.bl,styles.flex12]}>
                     <Text>Кольор</Text>
                </View>
               


                <View style={[styles.document__item_part,styles.bl,styles.flex12]}>
                     <Text>Матеріал</Text>
                </View>
                


                <View style={[styles.document__item_part,styles.bl,styles.flex12]}>
                     <Text>Ціна за шт.</Text>
                </View>
                



                <View style={[styles.document__item_part,styles.bl,styles.flex12]}>
                    <Text>Всього</Text>
                </View>
               

            </View>


            {
                order.attributes.productsDetails.map((el,i)=>(

                    <View style={[styles.bolt_border]}>
                    <View style={styles.document__item} >


                    <View style={[styles.document__item_part,styles.flex18]}>
                         <Text>{++i}</Text>
                    </View>
    
                   
    
    
                    <View style={[styles.document__item_part,styles.bl,styles.flex50,styles.bolt]}>
                       <Text>{el.title}</Text>
                    </View>
    
                    
    
                    <View style={[styles.document__item_part,styles.bl,styles.flex12]}>
                       <Text>{el.quantity} шт.</Text>
                    </View>
    
                    
    
    
                    <View style={[styles.document__item_part,styles.bl,styles.flex12]}>

                            {
                        el.color.name ? 
                        <>
                         <Text>{el.colorArticle}</Text>
                         <Text>{el.color.name}</Text>
                        </>
                         : 
                         <Text>-</Text>
                            }
                    </View>
                   
    
    
                    <View style={[styles.document__item_part,styles.bl,styles.flex12]}>
                         
                         {
                        el.material.name ? 
                        <>
                         <Text>{el.materialArticle}</Text>
                         <Text>{el.material.name}</Text>
                        </>
                         : 
                         <Text>-</Text>
                            }
                    </View>
                    
    
    
                    <View style={[styles.document__item_part,styles.bl,styles.flex12]}>
                         <Text>{el.price}$</Text>
                    </View>
                    
    
    
    
                    <View style={[styles.document__item_part,styles.bl,styles.flex12]}>
                        <Text>{el.quantity * el.price}$</Text>
                    </View>
                    
                    
    
                </View>

                {             
                    el.configurations.map((c,i)=>
                    <View style={[styles.configurationItem]} key={'pdf-document-configuration'+i+c.id}>
                        <Text>{c.title}</Text>
                        <Text>{c.value}</Text>
                    </View>
                    )
                }

{             
                    el.modules.map((c,i)=>

                    <View>

                    <View style={[styles.configurationItem,styles.p0]} key={'pdf-document-modules'+i+c.id}>



                        <View style={[styles.flex35]}>
                                <Text style={[styles.bdR,styles.padingBase5]}>{c.title}</Text>
                        </View>
                       
                        <View style={[styles.flex16,styles.padingBase5,styles.bdR]}>
                                <Text style={[styles.cen]}>{c.quantity} шт.</Text>        
                        </View>


                        
                        <View style={[styles.flex_auto]}>

                        </View>

                        <View style={[styles.bdL,styles.flex15]}>
                             <Text style={styles.cen}>{c.price}$</Text>
                        </View>

                        <View style={[styles.flex15,styles.bdL]}>
                            <Text style={styles.cen}>{c.price * c.quantity}$</Text>
                        </View>
                   
                    </View>

                    {
    c.submodules.map((s,i)=>
        <View style={styles.submodules} key={i+'submodules'+s.id}>

                <Text>
                {submodulesArr.find((e) => e.id === s.submodule)?.attributes.titleForSelectedType}
                </Text>

                <Text>      
                {submodulesArr.find((e) => e.id === s.submodule)?.attributes?.hooks ? submodulesArr.find((e) => e.id === s.submodule)?.attributes?.hooks.split(';')[s.currentIndex] : 't'}
                </Text>

        </View>
        
    )

}

                    </View>



                    )
                }
                
             

                </View>
                
                ))
            }

        </View>
        
      </Page>
    </Document>
  );

export default AdminTextualPDF;