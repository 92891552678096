import React from "react";

function CatalogFilterElement(props) {

    return <li
        onClick={(event) => {
            props.close()
            try {
                if (props.all) {
                    props.onALLClick()
                } else {

                    props.onChildItemClick(props)
                }
            } catch (error) {
                console.log(error);
            }
        }}
        //data-url={el?.attributes?.url}
    >
        {props.title}
    </li>
}

export default CatalogFilterElement
