import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import useFetch from "../../../../Hooks/useFetch";
import Skeleton from "react-loading-skeleton";
import PcCatalogElement from "./pcCatalogElement";

function PcCatalog({text, categoryOpen, data, loading, error}) {
    const [skeletonState, setSkeletonState] = useState(true);
    const languageInformation = useSelector((state) => state.language.language);
    const {categoryID} = useParams();
    const [loadingDataFinish, setLoadingDataFinish] = useState(true)
    const {
        data: configuratorData,
        loading: loadingData,
        error: errorData,
    } = useFetch(
        process.env.REACT_APP_API_URL +
        `/categories?filters[masterId]=${categoryID}&locale=${languageInformation.language}&populate[configurator][populate][1]=img`,
    );
    useEffect(() => {
        setLoadingDataFinish(true)
    }, [categoryID]);
    useEffect(() => {
        if (loadingData == false) {
            //  setTimeout(() => {
            setLoadingDataFinish(false)
            // }, 55550)
        }
    }, [loadingData]);
    let subcategories = [];
    let collectionSets = [];

    let gridTypeClass = "sofas__net";

    let catalog = "";

    let gridType = "";
    try {
        gridType = data[0] ? data[0].attributes.gridType : "";
    } catch (error) {
        console.log(error);
    }
    try {

        if (
            data[0].attributes.collectionsTitle &&
            data[0].attributes.collectionsTitle.length > 2
            && data[0].attributes.configuratorButton != true
        )

            collectionSets.push({
                collection: true,
                attributes: {
                    img: {data: data[0].attributes.collectionsImg.data[0]},
                    title: data[0].attributes.collectionsTitle,
                    url:
                        "collections/" +
                        data[0].attributes.collectionsTitle +
                        "/" +
                        data[0].attributes.masterId +
                        `?category=${data[0].attributes.masterId}`,
                },
            });
    } catch (error) {
    }

    if (gridType === "grid_1C-3") {
        if (data && data[0]) {
            let subcategoriesArray = [
                ...collectionSets,
                ...data[0].attributes.subcategories.data,
                ...data[0].attributes.categoriesInCategories.data,
            ];
            if (configuratorData && configuratorData[0]) {
                let configurator = configuratorData[0].attributes.configurator.data;
                if (configurator) {
                    configurator.configurator = true;
                    subcategoriesArray = [
                        subcategoriesArray[0],
                        configurator,
                        ...subcategoriesArray.slice(1),
                    ];
                }
            }
            subcategories = [...subcategoriesArray];
        }
        gridTypeClass = "bedroom__net ";
        catalog = (
            <>
                <div className="bedroom__net-first">
                    {subcategories.slice(0, 2).map((category, index) => (
                        <PcCatalogElement
                            data={data}
                            skeletonState={skeletonState}
                            setSkeletonState={setSkeletonState}
                            key={index}
                            category={category}
                            index={index}
                            text={text}
                            categoryID={categoryID}
                        />
                    ))}
                </div>
                <div className="bedroom__net-second">
                    {subcategories.slice(2).map((category, index) => (
                        <PcCatalogElement
                            data={data}
                            skeletonState={skeletonState}
                            setSkeletonState={setSkeletonState}
                            key={index}
                            category={category}
                            index={index}
                            text={text}
                            categoryID={categoryID}
                        />
                    ))}
                </div>
            </>
        );
    } else if (gridType === "grid_2-2-2") {
        gridTypeClass = "sofas__net ";
        if (data && data[0]) {
            subcategories = [
                ...collectionSets,
                ...data[0].attributes.subcategories.data,
                ...data[0].attributes.categoriesInCategories.data,
            ];
        }
        catalog = subcategories.map((category, index) => (
            <PcCatalogElement
                data={data}
                skeletonState={skeletonState}
                setSkeletonState={setSkeletonState}
                key={index}
                category={category}
                index={index}
                categoryID={categoryID}
                text={text}
            />
        ));
    } else if (gridType === "grid_ALLC1-2") {
        gridTypeClass = "sofas__net ";
        if (data && data[0]) {
            let subcategoriesArray = [
                ...collectionSets,
                ...data[0].attributes.subcategories.data,
                ...data[0].attributes.categoriesInCategories.data,
            ];
            let configurator = {
                configurator: true,
                attributes: {
                    img: {data: data[0].attributes.collectionsImg.data[0]},
                    title: data[0].attributes.collectionsTitle,
                    url:
                        "configurator"
                },
            };
            //  if (configurator) {
            configurator.configurator = true;
            subcategoriesArray = [configurator, ...subcategoriesArray];
            //  }
            subcategories = [...subcategoriesArray];
        }
        catalog = subcategories.map((category, index) => (
            <PcCatalogElement
                data={data}
                skeletonState={skeletonState}
                setSkeletonState={setSkeletonState}
                key={index}
                category={category}
                index={index}
                categoryID={categoryID}
                text={text}
            />
        ));
    }

    function returnGridType() {
        return gridTypeClass
        if (!loadingData) {
            return 'sofas__net '
        }
        return gridTypeClass
    }

    return (
        <>
            <div className={categoryOpen ? returnGridType() : "d-none"}>

                {error ? (
                    "error on server"
                ) : loadingDataFinish ? ( //loading &&
                    <>
                        {[1, 2, 3, 4].map((k, index) => (
                            <div key={index} className="skeleton-categories">
                                <Skeleton/>
                            </div>
                        ))}
                    </>
                ) : (
                    catalog
                )}
            </div>
        </>
    );
}

export default PcCatalog;
