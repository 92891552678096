import React from 'react';
import './adminPanel.scss';
import useFetch from './../../Hooks/useFetch';
import { addUser, clearUser } from '../../Redux/adminUserReducer';
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function AdminPanel() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [ adminLogin, setAdminLogin ] = React.useState('')
    const [ adminPassword, setAdminPassword ] = React.useState('')

    const [finalLogin, setFinalLogin] = React.useState('')
    const [finalPassword, setFinalPassword] = React.useState('')

    const [ typeInputPassword, setTypeInputPassword] = React.useState('password')
    const [ stateTextInvalid,setStateTextInvalid ] = React.useState(false)


    
    const { data: user, loading: loadingUser, error: errorUser } = useFetch(`${process.env.REACT_APP_API_URL}/admin-panel-users?locale=uk-UA&filters[login][$eq]=${finalLogin}&filters[password][$eq]=${finalPassword}&populate=*`);
    

    const createFinalData = () => {
        setFinalLogin(adminLogin)
        setFinalPassword(adminPassword)
       
    }

  
    React.useEffect(()=>{

        if(finalLogin.length > 0 && finalPassword.length > 0){
            setStateTextInvalid(!user.length > 0)

            if(user.length > 0){
                dispatch(addUser(user[0].attributes.login))
                navigate('/admin-panel-orders');
            }
            

            setTimeout(()=>{
                setStateTextInvalid(false)
            },1000)
        }


          

        
    },[user]);


   

    return (
        <div className="admin-panel-wr">
            
            <div className="admin-panel">


                
      
                <input autoComplete="new-password"  value={adminLogin} onChange={(e)=>{setAdminLogin(e.target.value)}} type="text" className="login-s" placeholder='Login' />

                <div className="password__wr w100">

                    <img onClick={()=>{ setTypeInputPassword(typeInputPassword === 'password' ? 'text' : 'password') }}  src={process.env.PUBLIC_URL + '/img/view-password.svg'} alt="view" />

                   <input autoComplete="new-password" value={adminPassword} onChange={(e)=>{setAdminPassword(e.target.value)}} type={typeInputPassword} className='password-s' placeholder='Password' />
                </div>

                
                

                <button onClick={() => createFinalData() } className="button-s search__button">Увійти</button>
                
                <span className={stateTextInvalid ? "invalid-s active" : "invalid-s"} >Дані для входу невірні</span>
                
            </div>

            
        </div>
    );
}

export default AdminPanel;