import React, {useEffect} from "react";
import axios from "axios";

const useFetch = (url, settings = {findOne: false}) => {
    const [DATA, setDATA] = React.useState([]);
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [pagination, setPagination] = React.useState([]);
    const [lastUrl, setLastUrl] = React.useState();
    useEffect(() => {
        setLoading(true);
        // setTimeout(() => {
        axios
            .get(url, {
                headers: {
                    Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
                },
            })
            .then((res) => {
                if (settings.findOne == false) {

                    setDATA(res.data.data);
                } else {
                    if (res.data.data[0]) {

                        setDATA(res.data.data[0]);
                    } else {
                        setDATA(undefined)
                    }
                }
                setPagination(res.data.meta.pagination);
            })
            .catch((error) => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
        //   }, timeout);
        /*}*/
    }, [url]);
    return {data: DATA, loading, error, pagination};
};
export default useFetch;
