import React, {useEffect} from 'react';
import './Galery.scss';
// import Lightbox from "react-awesome-lightbox";
// import "react-awesome-lightbox/build/style.css";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css"
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Video from "yet-another-react-lightbox/plugins/video";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

function Galery({images, closeLightbox, lightboxOpen, startIndex}) {
    const [open, setOpen] = React.useState(false);
    useEffect(() => {
        console.log(lightboxOpen);
        if (lightboxOpen) {
            setOpen(true)
        }
    }, [lightboxOpen]);


    return (
        <div>
            {open && <div className={['lightBoxBackTop'].join(" ")}>

            </div>}
            <Lightbox
                controller={{closeOnBackdropClick: true}}
                on={{
                    exiting: () => {
                        setOpen(false)
                        closeLightbox()
                    }
                }}
                open={open}
                close={() => {
                    setOpen(false)
                    closeLightbox()
                }}

                index={startIndex}
                zoom={{
                    scrollToZoom: false, doubleTapDelay: 150, doubleClickDelay: 250,
                    maxZoomPixelRatio: 8
                }}
                plugins={[Captions, Fullscreen, Slideshow, Video, Zoom]} //, Thumbnails
                slides={images ?
                    images.map((img, index) => ({
                        src:
                            process.env.REACT_APP_IMG + img?.attributes?.url + "?fullSize=true",
                        title: (img?.attributes?.caption && img?.attributes?.caption.length > 0) ? img?.attributes?.caption : "SHADEKO-" + (index + 1),
                    })) : []
                }
            />

        </div>
    );
}

export default Galery;
