//import './orderInformation.scss';
import React from 'react';
import useFetch from '../../Hooks/useFetch';
import ShoppingCartModule from '../../Components/ShoppingCartModule/';
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import PDFGenerator from '../../Components/PDFGenerator';

import './adminOrderSingle.scss';
import { useNavigate, useParams, Link } from 'react-router-dom'
import { useSelector,useDispatch } from 'react-redux'
import { clearUser } from '../../Redux/adminUserReducer';
import AdminEditPaidType from '../../Components/AdminEditPaidType';
import AdminChangeStatusOrder from '../../Components/AdminEditStatusOrder';
import AdminEditComment from '../../Components/AdminEditComment';
import AdminTextualPDF from '../../Components/AdminTextualPDF';


function AdminOrderSingle() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [editTypeOpen, setEditTypeOpen] = React.useState(false)
    const [editStatusOpen, setEditStatusOpen] = React.useState(false)
    const [editCommentOpen, setEditCommentOpen] = React.useState(false)
    
    const  userAuthorization = useSelector(state => state.adminUser.user);

    const checkAuthorization = () => {
        userAuthorization.length === 0  && navigate('/admin-panel')
    }

    React.useEffect(()=>{
        checkAuthorization();
    },[]);

    const logOut = () =>{
        dispatch(clearUser())
        navigate('/admin-panel')
    }


    const { timestamp } = useParams();

    const { data: text, loading: loadingText, error: errorText } = useFetch(`${process.env.REACT_APP_API_URL}/Text-for-pdfs?locale=uk-UA&populate=*`);


   
    const [closeLightbox, setCloseLightbox] = React.useState(false);
	const [lightboxSrc,setLightboxSrc] = React.useState('');

    const growImage = (e) => {
        setLightboxSrc(e.target.src);
        setCloseLightbox(!closeLightbox);	
    }


    const closeGalery = () => {
        setLightboxSrc('');
        setCloseLightbox(!closeLightbox);
    }

    const {data, loading, error} = useFetch(process.env.REACT_APP_API_URL+`/unpaid-orders?filters[timeStamp][$eqi]=${timestamp}&populate=*`);

    
    

    let statusObj;

    if(data[0]?.attributes?.status === 'Pending maneger review'){

        statusObj = {
            text: text[0]?.attributes?.statusOrderOption1,
            color: 'redC',
        }

    }else if(data[0]?.attributes?.status === 'Processing'){

        statusObj = {
            text: text[0]?.attributes?.statusOrderOption2,
            color: 'yellowC',
        }


    }else if(data[0]?.attributes?.status === 'Shipped'){

        statusObj = {
            text: text[0]?.attributes?.statusOrderOption3,
            color: 'yellowC',
        }
    
    }else if(data[0]?.attributes?.status === 'Delivered'){

        statusObj = {
            text: text[0]?.attributes?.statusOrderOption4,
            color: 'greenC',
        }
     
    }else if(data[0]?.attributes?.status === 'Canceled'){

        statusObj = {
            text: text[0]?.attributes?.statusOrderOption5,
            color: 'redC',
        } 

    }else if(data[0]?.attributes?.status === 'Return requested'){

        statusObj = {
            text: text[0]?.attributes?.statusOrderOption6,
            color: 'yellowC',
        } 

    }else if(data[0]?.attributes?.status === 'Return complited'){

        statusObj = {
            text: text[0]?.attributes?.statusOrderOption7,
            color: 'greenC',
        }


    }else{
        statusObj = {
            text: 'Undefined',
            color: 'redC',
        }
    }


    
    
      const [showPDF, setShowPDF] = React.useState(false);
    
      const handleGeneratePDF = () => {
        setShowPDF(true);
      };


      const clientInformation = data[0]?.attributes?.clientInformation;


    return (

        <div className='content orderInformation'>
            
         
{
            (data[0] && statusObj.text) &&
            <div className="orderInformation__net">

                <div className="orderInformation__net-item">

                    <section>
                        <p>{text[0]?.attributes?.allPriceOrder}</p>
                        <span>{data[0]?.attributes?.totalPrice}$</span>
                    </section>

                    <section>
                        <p>{text[0]?.attributes?.promocode}</p>
                        <span>{data[0]?.attributes?.promocodePercent}%</span>
                    </section>

                    <section>
                        <p>{text[0]?.attributes?.typeOrder}</p>


                        {
                            data[0]?.attributes?.type === 'Not paid' ? (
                                <span>{text[0]?.attributes?.typeOrderOption1}</span>
                            ) : data[0]?.attributes?.type === 'Paid' ? (
                                <span>{text[0]?.attributes?.typeOrderOption2}</span>
                            ) : data[0]?.attributes?.type === 'Part paid' ? (
                                <div>
                                    <span>Оплачено частину</span>
                                    <span className='part-paid-number'>{data[0]?.attributes?.PartPaid}$</span>
                                </div>
                            ) : null
                        }


                        <img onClick={() => {
                            setEditTypeOpen(!editTypeOpen)
                        }} alt="button edit" src={process.env.PUBLIC_URL + '/img/edit-for-admin-panel.svg'} className="edit-icon"/>

                    </section>

                    <section>

                        <p>{text[0]?.attributes?.statusOrder}</p>
                        <span className={statusObj.color}>{statusObj.text.toString()}</span>
                        <img onClick={() => {
                            setEditStatusOpen(!editStatusOpen)
                        }} alt="button edit" src={process.env.PUBLIC_URL + '/img/edit-for-admin-panel.svg'} className="edit-icon"/>

                    </section>

                    <section>

                        <p>{text[0]?.attributes?.commentOrder}</p>
                        <span>{data[0]?.attributes?.commentForOrder ? data[0]?.attributes?.commentForOrder : text[0]?.attributes?.commentOrderEmpty}</span>
                        <img onClick={() => {
                            setEditCommentOpen(!editCommentOpen)
                        }} alt="button edit" src={process.env.PUBLIC_URL + '/img/edit-for-admin-panel.svg'} className="edit-icon"/>

                    </section>

                    <section>
                        <p>Доставка:</p>
                        <span>{clientInformation?.shipType ? clientInformation?.shipType : '-'}</span>
                    </section>


                    <section>
                        <p>Імя потепційного клієнта:</p>
                        <span>{clientInformation?.Name ? clientInformation?.Name : '-'}</span>
                    </section>


                    <section>
                        <p>Прізвище потенційного клієнта:</p>
                        <span>{clientInformation?.LastName ? clientInformation?.LastName : '-'}</span>
                    </section>

                    <section>
                        <p>Компанія потенційного клієнта:</p>
                        <span>{clientInformation?.CompanyName ? clientInformation?.CompanyName : '-'}</span>
                    </section>

                    <section>
                        <p>Країна потенційного клієнта:</p>
                        <span>{clientInformation?.Country ? clientInformation?.Country : '-'}</span>
                    </section>


                    <section>
                        <p>Місто потенційного клієнта:</p>
                        <span>{clientInformation?.City ? clientInformation?.City : '-'}</span>
                    </section>

                    <section>
                        <p>Регіон потенційного клієнта:</p>
                        <span>{clientInformation?.Region ? clientInformation?.Region : '-'}</span>
                    </section>

                    <section>
                        <p>Zip-code потенційного клієнта:</p>
                        <span>{clientInformation?.ZipCode ? clientInformation?.ZipCode : '-'}</span>
                    </section>

                    <section>
                        <p>Адрес потенційного клієнта:</p>
                        <span>{clientInformation?.Adress ? clientInformation?.Adress : '-'}</span>
                    </section>

                    <section>
                        <p>Номер телефону потенційного клієнта:</p>
                        <span>{clientInformation?.Phone ? clientInformation?.Phone : '-'}</span>
                    </section>

                    <section>
                        <p>Email адрес потенційного клієнта:</p>
                        <span>{clientInformation?.EmailAddress ? clientInformation?.EmailAddress : '-'}</span>
                    </section>


                    <section>
                        <p>Примітки до замовлення потенційного клієнта:</p>
                        <span>{clientInformation?.OrderNotes ? clientInformation?.OrderNotes : '-'}</span>
                    </section>

                    <section>
                        {
                            <PDFGenerator
                                products={data[0]?.attributes?.productsDetails}
                                totalPrice={data[0]?.attributes?.totalPrice}
                                promocodePercent={data[0]?.attributes?.promocodePercent}
                                type={data[0]?.attributes?.type}
                            />
                        }
                    </section>


                    <section>
                        {
                            <AdminTextualPDF
                                order={data[0]}
                            />
                        }
                    </section>


                    {
                        data[0]?.attributes?.productsDetails.map((item) => (
                            <>
                                <div key={'orderInformation' + item.id} className="orderInformation__product">

                                    <div className="orderInformation__product-img">
                                        <img onClick={(e) => growImage(e)} src={process.env.REACT_APP_IMG + item?.img} alt="photo"/>
                                    </div>


                                    <div className="orderInformation__product-text">
                                        <h4>{item?.title}</h4>

                                        <section>
                                            <p>{text[0]?.attributes?.Quantity}</p>
                                            <span>{item?.quantity}</span>
                                        </section>

                                        {
                                            item?.quantity !== 1 &&

                                            <section>
                                                <p>{text[0]?.attributes?.priceSingle}</p>
                                                <span>{item?.price}$</span>

                                                {/* <span>{item?.stockQuantity === '0' && +item?.price / 2 }$</span> */}

                                            </section>
                                        }


                                        <section>
                                            <p>{text[0]?.attributes?.allPriceSingle}</p>
                                            <span>{+item?.quantity * +item?.price}$</span>
                                            {/* <span>{item?.stockQuantity === '0' && +item?.price / 2+'$' }</span> */}
                                        </section>


                                        {
                                            item?.color?.name &&

                                            <section>
                                                <p>{text[0]?.attributes?.Color}</p>

                                                <div className='d-flex a-i-center color-wr'>
                                                    <span>{item?.color?.name}</span>
                                                    <img onClick={(e) => growImage(e)} src={item?.color?.img} alt="color"/>
                                                </div>

                                            </section>

                                        }

                                        {
                                            item?.material?.name &&
                                            <section>
                                                <p>{text[0]?.attributes?.Material}</p>

                                                <div className='d-flex a-i-center material-wr'>
                                                    <span>{item?.material?.name}</span>
                                                    <img onClick={(e) => growImage(e)} src={item?.material?.img} alt="material"/>
                                                </div>


                                            </section>
                                        }

                                        {
                                            item?.configurations.map((configuration) => (
                                                <section key={'configurationOrder' + configuration.id}>
                                                    <p>{configuration.title}</p>
                                                    <span>{configuration.value}</span>
                                                </section>
                                            ))
                                        }


                                    </div>
                                </div>
                                <div className="drawer__item-modules">
                                    {
                                        item?.modules.map((module) => (
                                            <ShoppingCartModule
                                                key={'drawerModules' + module.id}
                                                module={module}
                                                growImage={growImage}


                                            />

                                        ))
                                    }

                                    {
                                        closeLightbox &&

                                        <Lightbox
                                            image={lightboxSrc}
                                            onClose={closeGalery}
                                        />

                                    }

                                </div>
                            </>


                        ))
                    }


                </div>

            </div>
}

            <Link to='/admin-panel-orders'>
                <img className='catalog-orders-button' src={process.env.PUBLIC_URL + '/img/list-order-admin.svg'} alt="catalog orders"/>
            </Link>


            <img onClick={() => logOut()}  className='admin-exit' src={process.env.PUBLIC_URL + '/img/exit-admin.svg'} alt="exit" />
			
            {
                    data[0] && <AdminEditPaidType
        editTypeOpen={editTypeOpen}
        setEditTypeOpen={setEditTypeOpen}
        type={data[0]?.attributes?.type}
        partPaid={data[0]?.attributes?.PartPaid} 
        id={data[0]?.id}
          />
            }


            {
            data[0] && <AdminChangeStatusOrder
            editStatusOpen={editStatusOpen}
            setEditStatusOpen={setEditStatusOpen}
            currentStatus={data[0]?.attributes?.status}
            id={data[0]?.id}
            
          />
            }

            {
                <AdminEditComment
                    id={data[0]?.id}
                    editCommentOpen={editCommentOpen}
                    setEditCommentOpen={setEditCommentOpen}      
                    currentComment={data[0]?.attributes?.commentForOrder} 
                 />
            }
            


        </div>



    );
}


export default AdminOrderSingle;
