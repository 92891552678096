export function changeCurrency(value, store, simple) {
    try {
        const {currencies, currency} = store;
        const find = currencies.find(k => k.label.toUpperCase() === currency.toUpperCase());

        if (!find) {
            return formatNumberToString(value) + ' $';
        }

        const roundedValue = roundToNearestTen(value * find.value);
        if (simple) {
            return roundedValue
        }
        return formatNumberToString(roundedValue) + ' ' + find.symbol;
    } catch (error) {
        return formatNumberToString(value) + ' $';
    }
}

export function roundToNearestTen(num) {
    return Math.ceil(num);
}

function formatNumberToString(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
