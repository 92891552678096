import {useEffect} from "react";

const BlockImageDownloader = () => {
    useEffect(() => {
        // Funkcja blokująca menu kontekstowe dla obrazów
        const handleContextMenu = (event) => {
            if (event.target.tagName === 'IMG') {
                event.preventDefault();
            }
        };

        // Dodaj nasłuchiwacz zdarzeń na całym dokumencie
        document.addEventListener('contextmenu', handleContextMenu);

        // Użycie MutationObserver do monitorowania dodawania nowych obrazów
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                mutation.addedNodes.forEach((node) => {
                    if (node.tagName === 'IMG') {
                        node.addEventListener('contextmenu', handleContextMenu);
                    }
                    if (node.querySelectorAll) {
                        node.querySelectorAll('img').forEach((img) => {
                            img.addEventListener('contextmenu', handleContextMenu);
                        });
                    }
                });
            });
        });

        // Konfiguracja obserwatora
        observer.observe(document.body, {childList: true, subtree: true});

        // Czyszczenie przy demontażu komponentu
        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
            observer.disconnect();
        };
    }, []);

    return null;
};

export default BlockImageDownloader;
