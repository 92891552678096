import React from "react";
import useFetch from "../../../Hooks/useFetch";

import {useSelector} from "react-redux";
import {Helmet} from 'react-helmet-async';

function PcHelmet({text}) {

    const canonicalUrl = window.location.href;
    const languageInformation = useSelector(state => state.language.language);
    const {
        data: seo,
        loading: seoLoading,
        error: seoError
    } = useFetch(process.env.REACT_APP_API_URL + `/seos?locale=${languageInformation.language}&filters[route]=dining&populate=*`);

    return (
        <>
            <Helmet>
                <title>{seo[0]?.attributes?.title}</title>
                <meta property="og:title" content={seo[0]?.attributes?.title}/>

                <meta name="description" content={seo[0]?.attributes?.description}/>
                <meta property="og:description" content={seo[0]?.attributes?.description}/>

                <meta name="keywords" content={seo[0]?.attributes?.keywords}/>

                <link rel="canonical" href={canonicalUrl}/>
                <meta name="Publisher" content="https://shadeko.eu/"/>
            </Helmet>
        </>
    );
}

export default PcHelmet;
