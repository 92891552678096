import CatalogFilterContainer from "../../../Components/Catalog/CatalogFilter/catalogFilter";
import React from "react";

import {Link} from 'react-router-dom';
import safe from "../../../functions/safe";

import {useSelector} from 'react-redux';

function PcNav({data, home, text, categoryOpen, filtersOpen, setFiltersOpen, setCategoryOpen, skipCategory}) {
    const height = useSelector(state => state.language).headerHeight

    return (
        <>
            <div className={["content mobileNav", filtersOpen ? "mobileOpen" : ''].join(" ")}>

                {!home ? <div className="sofas__bread-crumbs d-flex a-i-center">
                    <Link to='/'>{text[0]?.attributes?.breadCrumbHome}</Link>
                    <span>/</span>
                    <p>{safe(data[0]?.attributes?.navName)}</p>
                </div> : ''}

                <div style={{top: height - 1 + 'px'}} className="mobile__controls d-flex a-i-center mobileNavFixed mobileNav">
                    {skipCategory !== true && <button onClick={() => {
                        setCategoryOpen(!categoryOpen)
                    }}>

                        <span>{text[0]?.attributes?.mobileControlsCategories}</span>

                        <img className={categoryOpen && 'rotate'} src={process.env.PUBLIC_URL + '/img/header__list-arrow.svg'} alt="arrow"/>
                    </button>}

                    <button onClick={() => {
                        setFiltersOpen(!filtersOpen)
                    }}>
                        <span>{text[0]?.attributes?.mobileControlsFilter}</span>
                        <img className={filtersOpen && 'rotate'} src={process.env.PUBLIC_URL + '/img/header__list-arrow.svg'} alt="arrow"/>
                    </button>
                </div>


            </div>
            <CatalogFilterContainer home={home} filtersOpen={filtersOpen}/>

        </>
    );
}

export default PcNav;
