import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

const ScrollToTop = () => {
    const {pathname} = useLocation();

    useEffect(() => {
        // Płynne przewijanie do góry
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [pathname]);

    return null; // Nie renderujemy nic
};

export default ScrollToTop;

