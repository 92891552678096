import './headerListItem.scss';
import React from 'react';
import {Link} from 'react-router-dom';

function HeaderListItem(props) {


    // let expr = `/subcategories?filters[url][$containsi]=${props.title.attributes.url}&locale=${props.languageInformation.language}&populate=*`;
    //
    // if (props.title.attributes.url === 'luxury-style') {
    //     expr = `/categories-luxury?locale=${props.languageInformation.language}&populate=*`;
    // }


    // let {data, loading, error} = useFetch(process.env.REACT_APP_API_URL + expr);

    const data = props.subcategories
    return (

        <>

            <div className="header__list-title d-flex a-i-center">

                <Link to={props.title.attributes.url} className='transform'>{props.title.attributes.name}</Link>

                <img onClick={props.onItemClick} className={props.isActive ? 'rotate' : ""} src={process.env.PUBLIC_URL + '/img/header__list-arrow.svg'}
                     alt="arrow"/>
            </div>


        </>

    );
}

export default HeaderListItem;
