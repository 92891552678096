import React from 'react';
import './configuratorModal.scss';
import ConfiguratorElementColor from '../../Components/ConfiguratorElementColor';
import ConfiguratorElementLong from '../../Components/ConfiguratorElementLong';
import ConfiguratorElementShort from '../../Components/ConfiguratorElementShort';
import useFetch from '../../Hooks/useFetch';
import axios from 'axios';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, {Navigation, Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import SingleModule from '../../Components/SingleModules';
import Skeleton from 'react-loading-skeleton';
import {useSelector} from "react-redux";
import {changeCurrency} from "../../functions/changeCurrency";

// Install Swiper modules


function ConfiguratorModal({modalOpen, setModalOpen, id, activeTab, setActiveTab, setProduct1, setProduct2, setProduct3, text}) {

    SwiperCore.use([Navigation, Pagination]);
    const isTouchDevice = 'ontouchstart' in window || navigator.msMaxTouchPoints;


    const [colorsIdArr, setColorsIdArr] = React.useState([]);
    const [materialsIdArr, setMaterialsIdArr] = React.useState([]);
    const [colorsApi, setColorsApi] = React.useState([]);
    const [materialsApi, setMaterialsApi] = React.useState([]);


    const [colorPreviewIndex, setColorPreviewIndex] = React.useState(600);
    const [materialPreviewIndex, setMaterialPreviewIndex] = React.useState(600);

    // const [currentColor, setCurrentColor] = React.useState(0);
    // const [currentMaterial, setCurrentMaterial] = React.useState(0);


    const [currentColor, setCurrentColor] = React.useState(0);
    const [currentMaterial, setCurrentMaterial] = React.useState(0);


    const [colorObj, setColorObj] = React.useState({});
    const [materialObj, setMaterialObj] = React.useState({});


    const [colorArticle, setColorArticle] = React.useState('');
    const [materialArticle, setMaterialArticle] = React.useState('');

    const [productQuantity, setProductQuantity] = React.useState(1);


    const {data: product, loading: loadingProduct, error: errorProduct} = useFetch(`${process.env.REACT_APP_API_URL}/products/${id}?populate=*`);
    const {
        data: modules,
        loading: loadingModules,
        error: errorModules
    } = useFetch(`${process.env.REACT_APP_API_URL}/modules?filters[products][id]=${id}&populate=*`);


    const [modulesState, setModules] = React.useState(modules);

    const cliickOnColor = (index) => {
        setCurrentMaterial(index);


        if (isTouchDevice) {

            if (materialPreviewIndex === index) {
                setMaterialPreviewIndex(600);
            } else {
                setMaterialPreviewIndex(index);
            }
        }
    }


    React.useEffect(() => {

        setModules([]);
        setTotalPrice(calculateTotalPrice([]));

        setModules(modules);
        setTotalPrice(calculateTotalPrice(modules));

    }, [loadingModules]);


    const [items, setItems] = React.useState([]);

    const calculateTotalPriceModules = () => {

        return items.reduce((total, item) => total + +item.price, 0);
    };


    const calculateSumModules = (id, price, title, currentValue) => {
        const itemIndex = items.findIndex(item => item.id === id);

        if (itemIndex !== -1) {
            // Якщо товар з таким id вже існує, оновлюємо ціну
            const updatedItems = [...items];

            updatedItems[itemIndex].price = price;
            updatedItems[itemIndex].value = currentValue;

            setItems(updatedItems);

        } else {
            // Інакше додаємо новий товар
            const newItem = {id: id, price: price, title: title, value: currentValue};
            setItems(prevItems => [...prevItems, newItem]);
        }
    };

    const calculateTotalPrice = (updatedModules) => {
        return updatedModules.reduce((total, module) => total + (module.attributes.price * module.attributes.initialAmount), 0);
    };

    const [modulesDrawer, setModulesDrawer] = React.useState([]);

    React.useEffect(() => {

        setModulesDrawer([])
        setColorPreviewIndex(600)
        setMaterialPreviewIndex(600)

        setProductQuantity(1)

        setColorObj({})
        setMaterialObj({})

        setColorArticle('')
        setMaterialArticle('')

        setCurrentColor(0)
        setCurrentMaterial(0)

        setItems([])

    }, [id]);

    const [totalPrice, setTotalPrice] = React.useState(calculateTotalPrice(modules));

    const handleModuleChange = (moduleId, changedPrice, newQuantity) => {


        const updatedModules = modulesState.map(module => {
            if (module.id === moduleId) {
                return {
                    ...module,
                    attributes: {
                        ...module.attributes,
                        initialAmount: newQuantity,
                    },
                };
            }
            return module;
        });

        setModules(updatedModules);
        const newTotalPrice = calculateTotalPrice(updatedModules);
        setTotalPrice(newTotalPrice);

    };


    const [submodulesConfigurations, setSubmodulesConfigurations] = React.useState([]);

    const CollectionModuleConfig = (newConfigs) => {

        newConfigs.forEach((newData) => {
            if (newData.initial === true) {
                setSubmodulesConfigurations((prev) => [...prev, newData]);
            } else {

                submodulesConfigurations.forEach((old) => {
                    if (old.module === newData.module) {

                        if (old.submodule === newData.submodule) {


                            const clearArr = submodulesConfigurations.filter((el) => el.submodule !== newData.submodule);

                            newConfigs.forEach((el) => {
                                clearArr.push(el);
                            })

                            setSubmodulesConfigurations(clearArr);

                        }

                    }
                    ;
                });

            }
        });

    };


    React.useEffect(() => {
        if (product) {

            setColorsIdArr([]);
            setMaterialsIdArr([]);

            setColorsIdArr(product?.attributes?.colors?.data.map(color => color.id));
            setMaterialsIdArr(product?.attributes?.materials?.data.map(materials => materials.id));

        }
        ;


    }, [product]);


    const nextStepConfigurator = () => {
        setModalOpen(!modalOpen);
        setActiveTab(++activeTab);
    }

    const clickOnColor = (index) => {

        setCurrentColor(index);
    }


    const colorsData = async () => {

        try {
            const responses = await Promise.all(
                colorsIdArr.map(el =>
                    axios.get(`${process.env.REACT_APP_API_URL}/colors/${el}?populate=*`, {
                        headers: {
                            Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
                        }
                    })
                )
            );

            const colorsData = responses.map(response => response.data.data);
            setColorsApi([]);
            setColorsApi(colorsData);

            setColorObj(
                {
                    img: process.env.REACT_APP_IMG + colorsData[0].attributes?.img?.data?.attributes?.url,
                    name: colorsData[0].attributes.name
                }
            );


        } catch (err) {
            // Обробка помилки
        }
    }

    const materialsData = async () => {

        try {
            const responses = await Promise.all(
                materialsIdArr.map(el =>
                    axios.get(`${process.env.REACT_APP_API_URL}/materials/${el}?populate=*`, {
                        headers: {
                            Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
                        }
                    })
                )
            );

            const materialsData = responses.map(response => response.data.data);

            setMaterialsApi([]);
            setMaterialsApi(materialsData);
            setMaterialObj(
                {
                    img: process.env.REACT_APP_IMG + materialsData[0].attributes?.img?.data?.attributes?.url,
                    name: materialsData[0].attributes.name
                }
            );
        } catch (err) {
            console.log(err);
            // Обробка помилки
        }
    }


    React.useEffect(() => {
        colorsData();
    }, [colorsIdArr]);

    React.useEffect(() => {
        materialsData();
    }, [materialsIdArr]);


    React.useEffect(() => {
        const extractedKeys = modulesState.map(item => ({
            id: item.id,
            price: item.attributes.price,
            title: item.attributes.name,
            quantity: item.attributes.initialAmount,
            img: process.env.REACT_APP_IMG + item?.attributes?.imgCard?.data?.attributes?.url,
            characteristics: item.attributes.characteristics,
            submodules: [],
        }));


        submodulesConfigurations.forEach((submodules) => {

            extractedKeys.forEach((el) => {

                if (el.id === submodules.module) {
                    el.submodules.push(submodules);
                }

            })

        })

        setModulesDrawer(extractedKeys);
    }, [modulesState, submodulesConfigurations]);

    const productConfig = () => {
        return {
            id: product.id,
            price:
                Number((product?.attributes?.price + +calculateTotalPriceModules() + +totalPrice)),

            /*     modules.length > 0 && !loadingModules ?
                 (totalPrice ? totalPrice + +calculateTotalPriceModules() : '0') :
                 (product?.attributes?.price ? product?.attributes?.price + +calculateTotalPriceModules() : '0'),*/
            title: product?.attributes?.title,
            img: product?.attributes?.img_card?.data?.attributes?.url,
            quantity: productQuantity,
            color: colorObj,
            colorArticle: colorArticle,
            material: materialObj,
            materialArticle: materialArticle,
            configurations: items,
            stockQuantity: product?.attributes?.stockQuantity.toString(),
            modules: modulesDrawer.filter((item) => item.quantity > 0),
        }
    }


    const addProductInConfigurator = () => {
        nextStepConfigurator();

        if (activeTab === 2) {
            setProduct1(productConfig());
        } else if (activeTab === 3) {

            setProduct2(productConfig());

        } else if (activeTab === 4) {

            setProduct3(productConfig());

        }

    }

    const currency = useSelector((state) => state.language.language);
    return (


        id !== 0 &&
        <div className={modalOpen ? 'modal__wr active' : 'modal__wr'}>


            <div className="modal__body">

                <div onClick={() => {
                    setModalOpen(!modalOpen)
                }} className="configurator__modal-close"></div>

                <h5>{text[0]?.attributes?.configuratorModalTitle}</h5>

                <div className="d-flex a-i-start configurator__modal-wr w100">


                    <div className="configurator__modal-body">

                        <section className='d-flex a-i-center modalConfigurator__quantity'>
                            <h4>{text[0]?.attributes?.modalCartQuantity}</h4>


                            <div className="modalConfigurator__quantity-counter">
                                <button onClick={() => {
                                    productQuantity > 1 && setProductQuantity(productQuantity - 1)
                                }} className="modalConfigurator__quantity-minus">-
                                </button>
                                <button className="modalConfigurator__quantity-body">{productQuantity}</button>
                                <button onClick={() => {
                                    setProductQuantity(productQuantity + 1)
                                }} className="modalConfigurator__quantity-plus">+
                                </button>
                            </div>


                        </section>


                        <div className='modules'>

                            {
                                loadingModules ?
                                    <div className="skeleton-modules">
                                        <Skeleton/>
                                        <Skeleton/>
                                    </div>
                                    :
                                    modules.map((el) => (
                                        <SingleModule
                                            module={el}
                                            key={el.id + 'module-part'}
                                            onChange={handleModuleChange}
                                            CollectionModuleConfig={CollectionModuleConfig}
                                        />
                                    ))
                            }
                        </div>


                        <div className='wrapper'>

                            {
                                colorsApi.length > 0 &&

                                <section className="product__color">
                                    <h4>Color:</h4>

                                    <div className="product__color-net">


                                        {colorsApi.map((item, index) => (
                                            <div className="material__wr">
                                                <div className={colorPreviewIndex === index ? "material__wr-preview active" : "material__wr-preview"}>
                                                    <h6>{item?.attributes?.article}</h6>

                                                    <img
                                                        key={item + index}
                                                        onClick={() => setCurrentMaterial(index)}
                                                        src={process.env.REACT_APP_IMG + item?.attributes?.img?.data?.attributes?.url}
                                                        alt="material"
                                                        className={currentColor === index ? 'material active' : 'material'}
                                                    />
                                                </div>

                                                <img
                                                    key={item + index}
                                                    onMouseOut={() => setColorPreviewIndex(600)}
                                                    onMouseEnter={() => setColorPreviewIndex(index)}
                                                    onClick={(e) => {

                                                        clickOnColor(index);
                                                        setColorArticle(e.target.dataset.article);
                                                        setColorObj({
                                                            img: e.target.src,
                                                            name: e.target.dataset.name,
                                                        });
                                                    }}
                                                    src={process.env.REACT_APP_IMG + item?.attributes?.img?.data?.attributes?.url}
                                                    alt="color"
                                                    className={currentColor === index ? 'color active' : 'color'}
                                                    data-article={item?.attributes?.article}
                                                    data-name={item?.attributes?.name}
                                                />
                                            </div>
                                        ))}
                                    </div>


                                </section>
                            }

                            {

                                materialsApi.length > 0 && <section className="product__color">
                                    <h4>Material:</h4>
                                    <div className="product__color-net">

                                        {
                                            materialsApi && materialsApi.map((item, index) => (
                                                <div className="material__wr">


                                                    <div className={materialPreviewIndex === index ? "material__wr-preview active" : "material__wr-preview"}>

                                                        <h6>{item?.attributes?.article}</h6>


                                                        <img
                                                            key={item + index}
                                                            onClick={() => {
                                                                setCurrentMaterial(index)
                                                            }}
                                                            src={process.env.REACT_APP_IMG + item?.attributes?.img?.data?.attributes?.url}
                                                            alt="material"
                                                            className={currentMaterial == index ? 'material active' : 'material'}

                                                        />


                                                    </div>

                                                    <img
                                                        onMouseOut={() => {
                                                            setMaterialPreviewIndex(600)
                                                        }}
                                                        onMouseEnter={() => {
                                                            setMaterialPreviewIndex(index)
                                                        }}
                                                        key={item + index + 'material'}
                                                        onClick={(e) => {
                                                            cliickOnColor(index);

                                                            setMaterialArticle(e.target.dataset.article);
                                                            setMaterialObj(
                                                                {
                                                                    img: e.target.src,
                                                                    name: e.target.dataset.name
                                                                } //e.target.dataset.article
                                                            );
                                                        }}


                                                        src={process.env.REACT_APP_IMG + item?.attributes?.img?.data?.attributes?.url}
                                                        alt="material" className={currentMaterial == index ? 'material active' : 'material'}
                                                        data-article={item?.attributes?.article}
                                                        data-name={item?.attributes?.name}
                                                    />
                                                </div>

                                            ))

                                        }

                                    </div>
                                </section>

                            }


                            {
                                errorProduct ? (
                                    <div>Error on server</div>
                                ) : loadingProduct ? (
                                    <div>Loading...</div>
                                ) : product ? (
                                    product?.attributes?.configurations?.data?.length ? (
                                        product?.attributes?.configurations?.data
                                            .sort((a, b) => ['color', 'short', 'long'].indexOf(a.attributes.type) - ['color', 'short', 'long'].indexOf(b.attributes.type))
                                            .map((el, index) => {
                                                switch (el.attributes.type) {
                                                    case 'color':
                                                        return (
                                                            <ConfiguratorElementColor
                                                                key={`${el.id}-modalColors-${index}`}
                                                                title={el.attributes.name}
                                                                arr={el.attributes.value}
                                                                id={el.id}
                                                            />
                                                        );
                                                    case 'short':
                                                        return (
                                                            <ConfiguratorElementShort
                                                                key={`${el.id}-modalShort-${index}`}
                                                                id={el.id}
                                                                title={el.attributes.name}
                                                                arr={el.attributes.value}
                                                                calculateSumModules={calculateSumModules}
                                                            />
                                                        );
                                                    case 'long':
                                                        return (
                                                            <ConfiguratorElementLong
                                                                key={`${el.id}-modalShort-${index}`}
                                                                id={el.id}
                                                                def={el.attributes.name}
                                                                arr={el.attributes.value}
                                                                calculateSumModules={calculateSumModules}
                                                            />
                                                        );
                                                    default:
                                                        return null;
                                                }
                                            })
                                    ) : (
                                        <h4></h4>
                                    )
                                ) : (
                                    <div>Product data not available</div>
                                )
                            }


                        </div>
                    </div>


                    <div className="configurator__modal-img-wr">


                        <div className="d-flex j-c-space a-i-start gap8">
                            <h4>{product?.attributes?.title}</h4>
                            <h4 className="price">

                                {changeCurrency(Number((product?.attributes?.price + +calculateTotalPriceModules() + +totalPrice) * productQuantity)
                                    /*modules.length > 0 && !loadingModules ?
                                        (totalPrice ? totalPrice + +calculateTotalPriceModules() : '0') :
                                        (product?.attributes?.price ? (product?.attributes?.price * productQuantity) + +calculateTotalPriceModules() :
                                         '0')*/, currency, true)

                                }
                            </h4>
                        </div>

                        <div className='configurator__modal-img'>


                            <Swiper
                                spaceBetween={30}
                                slidesPerView={1}
                                navigation
                                pagination={{clickable: true}}

                            >

                                {
                                    product?.attributes?.img_color_1?.data.map((el) => (
                                        <SwiperSlide key={'swiper-slide' + el.id}>
                                            <img src={process.env.REACT_APP_IMG + el?.attributes?.url} alt="Slide 1"/>
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>

                        </div>

                        <p className='description'>{product?.attributes?.description}</p>

                    </div>

                </div>

                <div className='d-flex j-c-center w100'>
                    <button onClick={() => {
                        addProductInConfigurator()
                    }} className='configurator__button'>{text[0]?.attributes?.configuratorModalButton}</button>
                </div>


            </div>

        </div>
    );
}

export default ConfiguratorModal;
