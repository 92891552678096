import React, {useEffect, useState} from "react";
import {Link, useParams, useSearchParams} from 'react-router-dom';
import axios from 'axios'

function PrevNextProduct({text, product}) {
    const [searchParams, setSearchParams] = useSearchParams();

    const {id, wishId} = useParams();

    const [next, setNext] = useState(0)
    const [prev, setPrev] = useState(0)
    const [productId, setProductId] = useState(0)
    useEffect(() => {

        if (id) {
            axios.get(`${process.env.REACT_APP_API_URL}/nextPrevProduct/` + id + '/' + addSortParamIfExist(), {
                headers: {
                    Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
                }
            }).then(response => {
                if (response?.data?.next) {
                    setNext(response?.data?.next)
                }
                if (response?.data?.prev) {
                    setPrev(response?.data?.prev)
                }
                setProductId(id)
            }).catch(error => {
                console.log(error);
            })
        }
    }, [id, searchParams.get("sort")]);


    function addSortParamIfExist() {
        if (searchParams.get("sort")) {
            return searchParams.get("sort")
        } else {
            return 0
        }

    }

    if (wishId) return ''
    return (
        <>
            <div className="product__bread-controller">
                {prev && prev.id != productId ? <Link
                    to={
                        "/product/" + prev?.title + "/" + prev.id
                    }
                >
                    <button>
                        <img src={process.env.PUBLIC_URL + '/img/double-arrow-left-icon.svg'} alt="arrow"/>

                        {text[0]?.attributes?.prevButtonText}
                    </button>


                </Link> : ''
                }
                {next && next.id != productId ? <Link
                    to={
                        "/product/" + next?.title + "/" + next.id
                    }
                >
                    <button>{text[0]?.attributes?.nextButtonText} <img className={'rotate'}
                                                                       src={process.env.PUBLIC_URL + '/img/double-arrow-left-icon.svg'} alt="arrow"/>
                    </button>
                </Link> : ''}
            </div>
        </>
    );
}

export default PrevNextProduct;
