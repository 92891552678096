import React, {useRef, useState} from 'react';


import LazyLoad from 'react-lazyload';
import {removeWatermarkToUrl} from "../../functions/noWatermark";

const ImageTest = ({src, alt, className, onLoad, lazy, onClick, removeWatermark, cover = false}) => {
    const [imageMain, setImageMain] = useState(false)

    const image1 = useRef()
    const [opacity, setOpacity] = useState(0)

    function smallImageLoad() {
        if (!lazy) {
            setImageMain(true)
        } else if (lazy == true) {
            setTimeout(() => {
                setImageMain(true)

            }, 400)
        }

        if (onLoad) {

            onLoad()
        }

    }

    function bigImageLoad() {
        let i = 0;
        const k = setInterval(() => {
            setOpacity((prev) => prev + 1)
            if (i++ > 3) {
                clearInterval(k)
            }
        }, 10)
    }

    const image2 = (<img className={className + ' ' + ' image2'}
                         src={process.env.REACT_APP_IMG + (removeWatermark == true ? removeWatermarkToUrl(src) : src)}

                         alt={alt}
                         fetchpriority="low"
                         style={{opacity: opacity, zIndex: 2}}
                         onLoad={bigImageLoad}
    />)
    return (
        <div className={['imageFastLoad', cover ? 'imageLazyCover' : ''].join(" ")} onClick={onClick}
            //style={{height: image1?.current?.clientHeight,}}
        >
            {/**/}
            {lazy === true ? (imageMain === true &&
                    <LazyLoad height={700} offset={400}>
                        {image2}
                    </LazyLoad>
                ) :
                (imageMain === true && image2
                )
            }

            {/**/}
            <img className={className}
                 src={process.env.REACT_APP_IMG + (removeWatermark == true ? removeWatermarkToUrl(src.replace('uploads/', "uploads/small_")) : src.replace('uploads/', "uploads/small_"))}
                 alt={alt}
                 style={{zIndex: 1}}//,opacity: 1 - opacity
                 onLoad={smallImageLoad}
                 ref={image1}
            />
        </div>)
};

export default ImageTest;
