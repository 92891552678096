import React from 'react';
import axios from 'axios'


function AdminEditStatusCostDelivery({ openEditStatus, setOpenEditStatus,id,status }) {


 
    const [currentOption, setCurrentOption] = React.useState(status)

    const types = [
        {
            data: 'Added',
            text: 'Додана',
        },
        {
            data: 'Reviewed',
            text: 'Переглянута',
        },
    ]


    const changeOptionType = (data) =>{
        setCurrentOption(data)
    }

    
    async function changeOptionOnServer(id, option) {

       
        try {
         
          const response = await axios.put(`${process.env.REACT_APP_API_URL}/calculate-shippings/${id}?populate=*`,
          {
          data: {
            status: option, 
          },
        },
         {
             headers: {
                 Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
              },
         });
         


      
          if (response.status === 200) {
            
            setOpenEditStatus(!openEditStatus)

            setTimeout(()=>{
                window.location.reload();
            },1000)
           

          } else {
            console.log('Статус відповіді не 200: ', response.status);
          }
        } catch (error) {
          console.error('Помилка: ', error);
        }
      }


    return (
        <div onClick={()=>{setOpenEditStatus(!openEditStatus)}} className={openEditStatus ? 'modal__wr active editPaidType' : 'modal__wr editPaidType'}>

        <div onClick={(e)=>{e.stopPropagation()}} className="modal__body">
            <h4 className='cen w100'>Змінити інфрмацію про статус:</h4>

            <ul className="types">
                {
                types.map((e,i)=>
                <li 
                
                onClick={()=>{changeOptionType(e.data)}} 
                key={'type-paid-admin-panel'+i} 
                className={currentOption === e.data ? 'active' : ''} 
                data-type={e.data}>{e.text}</li>
                )
                }
            </ul>

              <div className='d-flex j-c-center w100'>
                 <button className='search__button' onClick={()=>{changeOptionOnServer(id,currentOption)}}>Підтвердити</button>
              </div>   
            


        </div>

    </div>

    );
}

export default AdminEditStatusCostDelivery;