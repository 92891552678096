import { createSlice } from '@reduxjs/toolkit'

export const adminUserSlice = createSlice({
  name: 'adminUser',
  initialState: {
    user: ''
  },

  reducers: {

    addUser: (state, action) => {   
        state.user = action.payload
    },

    clearUser: (state) =>{
            state.user = '';
    }  

    },
    
   

});


// Action creators are generated for each case reducer function
export const { addUser,clearUser } = adminUserSlice.actions

export default adminUserSlice.reducer