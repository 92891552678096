import React,{useRef} from 'react';
import useFetch from '../../Hooks/useFetch';
import {Link} from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import { useSelector,useDispatch } from 'react-redux'
import { clearUser } from '../../Redux/adminUserReducer';
import AdminEditConsultationSingle from '../../Components/AdminEditConsultationSingle';

function AdminListConsultation() {

    const navigate = useNavigate()
    const [currentPage,setCurrentPage] = React.useState(1);
    const [orders, setOrders] = React.useState([]);

    const userAuthorization = useSelector(state => state.adminUser.user);
    const dispatch = useDispatch()

    const checkAuthorization = () => {
        userAuthorization.length === 0  && navigate('/admin-panel')
    }

    const logOut = () =>{
        dispatch(clearUser())
        navigate('/admin-panel')
    }

    React.useEffect(()=>{
        checkAuthorization();
    },[]);

    const componentRef = useRef(null);

    React.useEffect(() => {
        if (componentRef.current) {
          componentRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, []);

      const { data, loading, error,pagination } =
      useFetch(`${process.env.REACT_APP_API_URL}/zayavki-na-konsultacziyus?pagination[page]=${currentPage}&pagination[pageSize]=9&populate=*`);

      React.useEffect(() => {
        if (!loading && !error && data) {
            setOrders((prevOrders) => [...prevOrders, ...data]);
            
            if(componentRef.current && !loading && currentPage !== 1){
            setTimeout(()=>{

               (componentRef && componentRef.current) && componentRef.current.scrollIntoView({ behavior: 'smooth' });
            },0)
        }

        }
      }, [data, loading, error]);


    return (
        <div className='admin-panel-consultation__net-wr'>

<h4>Заявки на консультацію:</h4>
           
            <div className="admin-panel-consultation__net">

                

                
{
                orders.map((e,i)=>

                <AdminEditConsultationSingle
                key={i+'consultation-item'+e.id}
                e={e}
                 />

)}


            </div>

            
            <div ref={componentRef} className="w100 d-flex j-c-center">
                  <button onClick={()=>{setCurrentPage(currentPage + 1)}} className={pagination?.pageCount === currentPage ? 'product__more-button none' : 'product__more-button' }>Показати ще</button>
            </div>


            <Link to='/admin-panel-orders'>
					<img className='catalog-orders-button' src={process.env.PUBLIC_URL + '/img/list-order-admin.svg'} alt="catalog orders" />
			</Link>

               
			<img onClick={() => logOut()}  className='admin-exit' src={process.env.PUBLIC_URL + '/img/exit-admin.svg'} alt="exit" />


        </div>
    );
}

export default AdminListConsultation;