import React from 'react';
import axios from 'axios'


function AdminEditConsultationSingle({e}) {

    const [modalOpen,setModalOpen] = React.useState(false)
    const [currentOption, setCurrentOption] = React.useState(e.attributes.status)

    const  types = [
                {
                    data: 'Added',
                    text: 'Додана',
                },
                {
                    data: 'Reviewed',
                    text: 'Переглянута',
                },       
]


const changeOptionType = (data) =>{
    setCurrentOption(data)
}

async function changeOptionOnServer(id, option) {

       
    try {
     
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/zayavki-na-konsultacziyus/${id}?populate=*`,
      {
      data: {
        status: option, 
      },
    },
     {
         headers: {
             Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
          },
     });
     


  
      if (response.status === 200) {
        
        setModalOpen(!modalOpen)

        setTimeout(()=>{
            window.location.reload();
        },1000)
       

      } else {
        console.log('Статус відповіді не 200: ', response.status);
      }
    } catch (error) {
      console.error('Помилка: ', error);
    }
  }



    return (

              <div  className="admin-panel-consultation__item">     
                   <section>
                        <p>Ім'я:</p>
                         <span>{e.attributes.name}</span>
                   </section>
                   <section>
                        <p>Номер телефону:</p>
                         <span>{e.attributes.phone}</span>
                   </section>
                   <section>
                        <p>Статус:</p>

                        <div className="d-flex a-i-center gap8 edit">
                            <span className={e.attributes.status !== "Added" ? 'active' : ''}>{e.attributes.status === "Added" ? 'Додана' : 'Виконана'}</span>


                           
                {
                e.attributes.status === 'Added' &&
                            <img onClick={()=>setModalOpen(!modalOpen)} alt="button edit" src={process.env.PUBLIC_URL + '/img/edit-for-admin-panel.svg'} className="edit-icon" />
                }    
                     
                        </div>

                   </section>


                   <div onClick={()=>{setModalOpen(!modalOpen)}} className={modalOpen ? 'modal__wr active editPaidType' : 'modal__wr editPaidType'}>

            <div onClick={(e)=>{e.stopPropagation()}} className="modal__body">
                <h4 className='cen w100'>Змінити статус замовлення:</h4>


                  
            <ul className="types">
                {
                types.map((e,i)=>
                <li 
                
                onClick={()=>{changeOptionType(e.data)}} 
                key={'type-paid-admin-panel'+i} 
                className={currentOption === e.data ? 'active' : ''} 
                data-type={e.data}>{e.text}</li>
                )
                }
            </ul>



                  <div className='d-flex j-c-center w100'>
                     <button className='search__button' onClick={()=>{changeOptionOnServer(e.id,currentOption)}}>Підтвердити</button>
                  </div>   
                


            </div>

        </div>




                </div>

    );
}

export default AdminEditConsultationSingle;