import {BlobProvider, Document, Font, Image, Page, StyleSheet, Text, View,} from "@react-pdf/renderer";
import SendMailPDF from "../../Components/SendMailPDF";
import useFetch from "../../Hooks/useFetch";
import {useSelector} from "react-redux";
import React from "react";

Font.register({
    family: "Roboto",
    src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
});

const styles = StyleSheet.create({
    wrapper: {
        padding: "10px",
        fontFamily: "Roboto",
    },
    row: {
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
        // Відступ між рядками
    },
    image: {
        width: "30%", // Ширина фото (змініть за потребою)
        marginRight: "5px", // Відступ між фото і текстом
    },
    text: {
        width: "70%",
        fontSize: 12,
        // Ширина тексту (змініть за потребою)
    },
    w100: {
        width: "100%",
        fontSize: 10,
    },

    mb3: {
        marginBottom: "2px",
    },

    flex: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },

    flex2: {
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
        gap: "5px",
    },

    mr5: {
        marginRight: "5px",
    },

    fs12: {
        fontSize: "12px",
    },

    mb10: {
        marginBottom: "10px",
    },

    image32: {
        width: "45px",
        height: "45px",
    },

    logo: {
        marginBottom: "20px",
        width: "32px",
    },

    logoWrapper: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },

    initial: {
        fontSize: 12,
        lineHeight: "120%",
        marginBottom: "2px",
    },

    mt10: {
        marginTop: "10px",
    },

    modulesWrapper: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        fontSize: 10,
        flexWrap: "wrap",
        gap: "8px",
        marginTop: 5,
        marginBottom: 12,
    },

    moduleImage: {
        width: "100%",
        maxHeight: "110px",
        objectFit: "cover",
    },

    bd: {
        border: "1px solid #c5c5c5",
        padding: "3px",
        width: "100%",
        flex: "0 1 30%",
    },

    mb5: {
        marginBottom: 5,
    },

    i: {
        width: "30%",
        height: "100px",
    },
});

const MyDoc = (text, order) =>
    text[0] &&
    order[0] && (
        <Document>
            <Page size="A4" style={styles.wrapper}>
                <View style={[styles.logoWrapper]}>
                    <Image
                        style={styles.logo}
                        src={process.env.PUBLIC_URL + "/img/header__logo-short.png"}
                    />
                </View>

                <Text style={styles.initial}>{text[0]?.attributes?.initialText1}</Text>
                <Text style={styles.initial}>{text[0]?.attributes?.initialText2}</Text>
                <Text style={styles.initial}>{text[0]?.attributes?.initialText3}</Text>
                <Text style={styles.initial}>{text[0]?.attributes?.initialText4}</Text>
                <Text style={styles.initial}>{text[0]?.attributes?.initialText5}</Text>
                <Text style={styles.initial}>{text[0]?.attributes?.initialText6}</Text>
                <Text style={styles.initial}>{text[0]?.attributes?.initialText7}</Text>
                <Text style={styles.initial}>{text[0]?.attributes?.initialText8}</Text>

                <View style={[styles.w100, styles.flex, styles.fs12, styles.mt10]}>
                    <Text style={styles.mr5}>{text[0]?.attributes?.allPriceOrder}</Text>
                    <Text>{order[0]?.attributes?.totalPrice}$</Text>
                </View>

                <View style={[styles.w100, styles.flex, styles.fs12]}>
                    <Text style={styles.mr5}>{text[0]?.attributes?.promocode}</Text>
                    <Text>{order[0]?.attributes?.promocodePercent}%</Text>
                </View>

                <View style={[styles.w100, styles.flex, styles.fs12, styles.mb10]}>
                    <Text style={styles.mr5}>{text[0]?.attributes?.typeOrder}</Text>

                    {order[0]?.attributes?.type == "Not paid" ? (
                        <Text>{text[0]?.attributes?.typeOrderOption1}</Text>
                    ) : (
                        <Text>{text[0]?.attributes?.typeOrderOption2}</Text>
                    )}
                </View>

                {order[0]?.attributes?.productsDetails?.map((item, index) => (
                    <View key={index + "renderEmail" + item.id}>
                        <View style={styles.flex2}>
                            <Image style={styles.i} src={item?.img}/>
                            <View style={styles.row}>
                                <View style={styles.text}>
                                    <View style={[styles.w100, styles.mb3]}>
                                        <Text>{item.title}</Text>
                                    </View>

                                    <View style={[styles.w100, styles.flex, styles.mb3]}>
                                        <Text style={styles.mr5}>
                                            {text[0]?.attributes?.Quantity}
                                        </Text>
                                        <Text>{item.quantity}</Text>
                                    </View>

                                    {item?.color?.name && (
                                        <View style={[styles.w100, styles.flex, styles.mb3]}>
                                            <Text style={styles.mr5}>
                                                {text[0]?.attributes?.Color}
                                            </Text>
                                            <Text>{item?.color?.name}</Text>
                                        </View>
                                    )}

                                    {item?.material?.name && (
                                        <View style={[styles.w100, styles.flex, styles.mb3]}>
                                            <Text style={styles.mr5}>
                                                {text[0]?.attributes?.Material}
                                            </Text>
                                            <Text>{item?.material?.name}</Text>
                                        </View>
                                    )}

                                    {item?.quantity !== 1 && (
                                        <View style={[styles.w100, styles.flex, styles.mb3]}>
                                            <Text style={styles.mr5}>
                                                {text[0]?.attributes?.priceSingle}
                                            </Text>
                                            <Text>{item?.price}$</Text>

                                            {item?.stockQuantity === "0" && (
                                                <Text>
                                                    {item?.stockQuantity === "0" && +item?.price / 2}$
                                                </Text>
                                            )}
                                        </View>
                                    )}

                                    <View style={[styles.w100, styles.flex, styles.mb3]}>
                                        <Text style={styles.mr5}>
                                            {text[0]?.attributes?.allPriceSingle}
                                        </Text>
                                        <Text>{+item?.quantity * +item?.price}$</Text>

                                        {item?.stockQuantity === "0" && (
                                            <Text>
                                                {item?.stockQuantity === "0" && +item?.price / 2 + "$"}
                                            </Text>
                                        )}
                                    </View>

                                    {item?.configurations.map((configuration, i) => (
                                        <View
                                            key={i + "configurationOrder" + configuration.id}
                                            style={[styles.w100, styles.flex, styles.mb3]}
                                        >
                                            <Text style={styles.mr5}>{configuration.title}</Text>
                                            <Text>{configuration.value}</Text>
                                        </View>
                                    ))}
                                </View>
                            </View>
                        </View>

                        <View style={styles.modulesWrapper}>
                            {item?.modules?.map((el, i) => (
                                <View
                                    key={i + "createEmailListModules" + el.id}
                                    style={styles.bd}
                                >
                                    <Text>{el.title}</Text>

                                    <View style={styles.row}>
                                        <Text style={styles.mr5}>
                                            {text[0]?.attributes?.Quantity}
                                        </Text>
                                        <Text>{el.quantity}</Text>
                                    </View>

                                    <View style={styles.row}>
                                        <Text style={styles.mr5}>
                                            {text[0]?.attributes?.allPriceSingle}
                                        </Text>
                                        <Text style={styles.mb5}>{el.quantity * el.price}$</Text>
                                    </View>
                                    <Image style={styles.moduleImage} src={el?.img}/>
                                </View>
                            ))}
                        </View>
                    </View>
                ))}
            </Page>
        </Document>
    );

const CreatePDFforMail = ({text, email}) => {
    const [currentTrackNumber, setCurrentTrackNumber] = React.useState("");

    React.useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        setCurrentTrackNumber(queryParams.get("success"));
    }, []);

    const languageInformation = useSelector((state) => state.language.language);
    const {
        data: textPDF,
        loading: loadingTextPDF,
        error: errorTextPDF,
    } = useFetch(
        `${process.env.REACT_APP_API_URL}/Text-for-pdfs?locale=${languageInformation.language}&populate=*`,
    );

    const {
        data: order,
        loading: loadingOrder,
        error: errorOrder,
    } = useFetch(
        `${process.env.REACT_APP_API_URL}/unpaid-orders?filters[timeStamp][$eq]=${currentTrackNumber}&populate=*`,
    );
    React.useEffect(() => {
        console.log(order);

    }, [order]);
    return (
        <BlobProvider document={MyDoc(textPDF, order)}>
            {({blob, url, loading, error}) => {
                return (
                    blob &&
                    text &&
                    order[0] &&
                    email && (
                        <SendMailPDF
                            pdfBlob={blob}
                            text={text}
                            order={order[0]}
                            email={email}
                        />
                    )
                );
            }}
        </BlobProvider>
    );
};

export default CreatePDFforMail;
