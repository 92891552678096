import React from 'react';
import axios from 'axios';


function AdminEditComment({ id,editCommentOpen,setEditCommentOpen,currentComment }) {
    

    const [commentInput, setCommentInput] = React.useState(currentComment ? currentComment : '')


    React.useEffect(()=>{
        setCommentInput(currentComment)
    },[currentComment])

  

    const changeInput = (data) =>{
        setCommentInput(data)
    }



    async function changeOptionOnServer(id, comment) {
        try {
         
          const response = await axios.put(`${process.env.REACT_APP_API_URL}/unpaid-orders/${id}?populate=*`,
          {
          data: {
            commentForOrder: comment
          },
        },
         {
             headers: {
                 Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
              },
         });
         


      
          if (response.status === 200) {
            
           setEditCommentOpen(!editCommentOpen)

            setTimeout(()=>{
                window.location.reload();
            },1000)
           

          } else {
            console.log('Статус відповіді не 200: ', response.status);
          }
        } catch (error) {
          console.error('Помилка: ', error);
        }
      }
      
    




    return (
        <div onClick={()=>{setEditCommentOpen(!editCommentOpen)}} className={editCommentOpen ? 'modal__wr active editPaidType' : 'modal__wr editPaidType'}>

            <div onClick={(e)=>{e.stopPropagation()}} className="modal__body">
                <h4 className='cen w100'>Додати коментар для замовлення клієнта:</h4>

                <textarea className='input-edit-comment' value={commentInput} type="text" onChange={(e)=>{changeInput(e.target.value)}} />

                  <div className='d-flex j-c-center w100'>
                     <button className='search__button' onClick={()=>{changeOptionOnServer(id,commentInput)}}>Підтвердити</button>
                  </div>   
                


            </div>

        </div>

    );
}

export default AdminEditComment;