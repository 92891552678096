import React from 'react';
import Scrollbars from 'react-scrollbars-custom';
import CatalogFilterElement from "./catalogFilterElement";

function CatalogFilter(props) {
    function openPanel() {
        props.toggleActiveIndex()
    }


    // const [searchParams, setSearchParams] = useSearchParams();
    /*  useEffect(() => {

          if (searchParams.get(props.type) != null) {
              const k = searchParams.get(props.type).split("..")
              let k2 = {target: {innerText: k[0], dataset: {filter: k[1], type: props.type, url: k[2]}}}
              props.onChildItemClick(k2)
          }
      }, []);*/

    function title() {
   
        if (props.activeElementId) {
            // console.log(props.list, props.activeElementId.toString());
            const find = props.list.find(element => {
                // console.log(element.attributes.masterId?.toString(), props.activeElementId.toString());
                return element.attributes.masterId?.toString() === props.activeElementId.toString()

            });
            if (find) {

                return find.attributes.name ? find.attributes.name : find.attributes.title
            }
        }
        return props.title
    }

    function saveToUrl() {

    }

    return (


        <div
            className={['catalog__filter-item', props.block ? "dis" : ""].join(' ')}>

            {props.block}
            <div onClick={openPanel} className="cu-po catalog__filter-title d-flex a-i-center ">
                <span>{title()}</span>
                <img className={props.isActive ? 'rotate' : ''} src={process.env.PUBLIC_URL + '/img/header__list-arrow.svg'} alt="arrow"/>
            </div>
            <Scrollbars className={props.isActive ? "catalog__filter-body active" : "catalog__filter-body"}>

                <>
                    <CatalogFilterElement
                        onALLClick={props.onALLClick}
                        close={openPanel} title={props.all} all={true}
                        onChildItemClick={props.onChildItemClick}>
                    </CatalogFilterElement>
                    {props.list && props.list.map((el, index) => <CatalogFilterElement
                        key={index}
                        element={el}
                        close={openPanel}
                        onChildItemClick={props.onChildItemClick}
                        title={(props.type === 'subcategory' ? el.attributes?.title : el.attributes?.name)}/>)}
                </>

            </Scrollbars>

        </div>

    );
}

export default CatalogFilter;
/*

  <li
                            data-type={props.type}

                            data-filter={props.type === 'sort' ? (index === 0 ? '' : props.filterBody[index - 1]?.attributes?.body) : (index === 0 ? '' : props.filterBody + el.id)}
                            onClick={(event) => {
                                props.onChildItemClick(event)
                            }}
                            data-url={el?.attributes?.url}
                            key={el + index}>
                            {index === 0 ? el : (props.type === 'subcategory' ? el.attributes?.title : el.attributes?.name)}
                        </li>


 */
