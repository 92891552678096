import React, {useEffect, useRef, useState} from 'react';
import './CallMe.scss';
import PhoneInput from "react-phone-input-2";
import {makeRequest} from "../MakeRequest";
import axios from 'axios'; // Import axios do pobierania lokalizacji

function CallMe({text}) {
    const [modalOpen, setModalOpen] = useState(false);
    const [callMeOption, setCallMeOption] = useState(false);
    const [phone, setPhone] = useState('+380'); // Domyślny kod kraju
    const [countryCode, setCountryCode] = useState(''); // Kod kraju
    const nameRef = useRef(null);
    const [sendBlock, setSendBlock] = useState(false);


    useEffect(() => {
        async function fetchCountryCode() {
            try {
                const response = await axios.get('https://ipapi.co/json/');
                const code = response.data.country_code || 'UA';
                setCountryCode(code);
                setPhone(`+${code}`);
            } catch (error) {
                console.error('Error fetching country code:', error);
                setCountryCode('UA');
            }
        }

        fetchCountryCode();
    }, []);

    useEffect(() => {
        setCallMeOption(false);
    }, [modalOpen]);
    const [invalidName, setInvalidName] = useState(false)
    const [invalidPhone, setInvalidPhone] = useState(false)

    function sendRequest() {
        setInvalidName(false);
        setInvalidPhone(false);
        if (nameRef.current.value.length < 1) {
            setInvalidName(true)
        }
        if (phone.length < 5) {
            setInvalidPhone(true)
        }
        if (nameRef.current.value.length < 1 || phone.length < 5) return
        setSendBlock(true);
        makeRequest.post('/call-mes', {phone: phone, name: nameRef.current.value}).then(k => {
            console.log(k);
            setCallMeOption(false);
        }).catch(k => {
            console.log(k);
        }).finally(() => {
            setSendBlock(false);
        });
    }

    const [isTextVisible, setIsTextVisible] = useState(false);
    const [isShaking, setIsShaking] = useState(false);

    function start() {
        setTimeout(() => {
            setIsShaking(true);
        }, 3000);
        setTimeout(() => {
            setIsShaking(false);
        }, 3500);
        setTimeout(() => {
            setIsShaking(true);
        }, 4000);
        setTimeout(() => {
            setIsShaking(false);
        }, 4500);
        setTimeout(() => {
            setIsTextVisible(true);
        }, 6000);
        setTimeout(() => {
            setIsTextVisible(false);
        }, 9000);


    }

    useEffect(() => {
        start()
        const shakeInterval = setInterval(() => {
            start()
        }, 15000);

        return () => {
            clearInterval(shakeInterval);
        };
    }, []);
    return (
        <>
            <div
                className={`callMeIcon `}
                onClick={() => setModalOpen(!modalOpen)}
            >
                <div className={`container ${isTextVisible ? 'rotate' : ''}  ${isShaking ? 'shake' : ''}  `}>
                    <div className="front"><img src={process.env.PUBLIC_URL + '/img/whatsapp.png'} alt="call me icon"/></div>
                    <div className="back text" dangerouslySetInnerHTML={{__html: text[0]?.attributes?.contactButton}}></div>
                </div>

            </div>
            <div className={modalOpen ? "modal__wr calculate active" : "modal__wr calculate"}>
                <div className="modal__body">

                    <img className='callMeLogo' src={process.env.PUBLIC_URL + '/img/header__logo-short.png'} alt="header__logo-short"/>

                    <div onClick={() => {
                        setModalOpen(!modalOpen);
                    }} className="modal__close"></div>

                    <h4 className="callMeTitle">
                        {text[0]?.attributes?.callMe_popup_title}{"  "}
                        <img src={process.env.PUBLIC_URL + "/img/hand.png"} alt="hand"/>
                    </h4>
                    <p className={'callMeDescription'} dangerouslySetInnerHTML={{__html: text[0]?.attributes?.callMe_popup_description}}>

                        {/*Раді вітати у світі меблів Shadeco!*/}
                        {/*<br/> Ваш ідеальний інтер'єр – на відстані одного кліка!*/}
                    </p>
                    {!callMeOption &&
                        <div className={'callMe_options'}>
                            <a href={'https://wa.me/420723816300'} target={'_blank'}>
                                <button>{text[0]?.attributes?.callMe_popup_button_open}</button>
                            </a>
                            <button onClick={() => {
                                setCallMeOption(true);
                            }}>{text[0]?.attributes?.callMe_popup_button_leavePhone}
                            </button>
                        </div>
                    }
                    {callMeOption &&
                        <div className={"callMeForm"}>
                            <label>
                                <span className={"spanLabel"}>

                                {text[0]?.attributes?.callMe_popup_form_nameLabel}
                                </span>

                                <input ref={nameRef} className={['resetMargin', invalidName ? 'callMeInvalid' : ''].join(" ")}
                                       placeholder={text[0]?.attributes?.callMe_popup_form_namePlaceholder}/>
                            </label>
                            <label>
                                <span className={"spanLabel"}>

                                {text[0]?.attributes?.callMe_popup_form_phoneLabel}
                                </span>
                                <PhoneInput
                                    placeholder="+380 (11) 111 11 **"
                                    containerClass={invalidPhone ? 'callMeInvalid' : ""}
                                    value={phone}
                                    excludeCountries={['ru', 'kz']}
                                    onChange={setPhone}
                                    country={countryCode.toLowerCase()}
                                />
                            </label>

                            <button disabled={sendBlock} onClick={sendRequest}>
                                {text[0]?.attributes?.callMe_popup_form_send}
                            </button>
                        </div>
                    }
                </div>
            </div>
        </>
    );
}

export default CallMe;
