import React from 'react';
import axios from 'axios';


function AdminEditPaidType({editTypeOpen, setEditTypeOpen, type, partPaid, id }) {
 
    
    const [currentOption,setCurrentOption] = React.useState(type)
    const [partPaidNumber, setPartPaidNumber] = React.useState(partPaid ? partPaid : 0)

    const types = [
        {
            text: 'Оплачено',
            data: 'Paid'
        },
        {
            text: 'Не оплачено',
            data: 'Not paid'
        },
        {
            text: 'Оплачено частину',
            data: 'Part paid'
        },
    ]


    const changeOptionType = (data) =>{
        setCurrentOption(data)
    }

    async function changeOptionOnServer(id, option, part) {
        try {
         
          const response = await axios.put(`${process.env.REACT_APP_API_URL}/unpaid-orders/${id}?populate=*`,
          {
          data: {
            type: option, 
            PartPaid: +part
          },
        },
         {
             headers: {
                 Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
              },
         });
         


      
          if (response.status === 200) {
            
            setEditTypeOpen(!editTypeOpen)

            setTimeout(()=>{
                window.location.reload();
            },1000)
           

          } else {
            console.log('Статус відповіді не 200: ', response.status);
          }
        } catch (error) {
          console.error('Помилка: ', error);
        }
      }
      
    





    return (
        <div onClick={()=>{setEditTypeOpen(!editTypeOpen)}} className={editTypeOpen ? 'modal__wr active editPaidType' : 'modal__wr editPaidType'}>

            <div onClick={(e)=>{e.stopPropagation()}} className="modal__body">
                <h4 className='cen w100'>Змінити інфрмацію про оплату:</h4>

                <ul className="types">
                    {
                        types.map((e,i)=>(

                            <div className='d-flex a-i-center'>    
                            <li 
                            onClick={()=>{changeOptionType(e.data)}} 
                            key={'type-paid-admin-panel'+i} 
                            className={currentOption === e.data ? 'active' : ''} 
                            data-type={e.data}>{e.text}</li>
                            {e.data === 'Part paid' &&
                                <>
                                     <input onChange={(e)=>{setPartPaidNumber(e.target.value)}} value={partPaidNumber} type="text" />
                                     <span>$</span>
                                </>
                               
                            } 
                            </div>
                        ))
                    }
                </ul>

                  <div className='d-flex j-c-center w100'>
                     <button className='search__button' onClick={()=>{changeOptionOnServer(id,currentOption,partPaidNumber)}}>Підтвердити</button>
                  </div>   
                


            </div>

        </div>

    );
}

export default AdminEditPaidType;